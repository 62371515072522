import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { add } from "ionicons/icons";
import { v4 } from 'uuid';
import { Virtuoso } from 'react-virtuoso';

import { sqlite } from "../App";
import { GlobalConfig, Modulo } from "../config";
import { useTranslation } from "react-i18next";

export declare type FitlerProps = {
    searchValues: { [key: string]: any; }
    setSearchValues: React.Dispatch<React.SetStateAction<{
        [key: string]: any;
    }>>
}

export declare type ListViewComponentProps = {
    Document: { [key: string]: any }
    CollectionUrl: string,
    Editable: boolean
}


declare type Props = {
    Collection: Modulo
}

const ListView: React.FC<Props> = ({ Collection }) => {
    const { t } = useTranslation()
    const pageSize = 20
    const [page, setPage] = useState(0)
    const [searchValues, setSearchValues] = useState<{ [key: string]: any }>({})
    const [filteredRecords, setFilteredRecords] = useState<{ [key: string]: any }[]>([])
    const [hasMore, setHasMore] = useState(true)
    const [db, setDB] = useState<SQLiteDBConnection | null>(null)

    const initialFilter = useCallback(async (page: number, pageSize: number) => {
        if (db) {
            const records = await Collection.Methods.filterListView(db, searchValues, page, pageSize)
            if (records.length === 0) {
                setHasMore(false)
            }
            setFilteredRecords(records)
        }
    }, [db, Collection, searchValues])

    const connectToDB = useCallback(async () => {
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        let dbConn: SQLiteDBConnection
        if (ret.result && isConn) {
            dbConn = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            dbConn = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
        }
        setDB(dbConn)
    }, [])

    useEffect(() => {
        connectToDB()
    }, [connectToDB])

    useEffect(() => {
        const reset = 0
        setPage(reset)
        setHasMore(true)
        initialFilter(reset, pageSize)
    }, [searchValues, initialFilter])


    const fetchMoreData = async (ev: any) => {
        if (hasMore && db) {
            const records = await Collection.Methods.filterListView(db, searchValues, page + 1, pageSize)
            if (records.length === 0) {
                console.log("no more")
                setHasMore(false)
            }

            setFilteredRecords(filteredRecords.concat(records));
            setPage(page + 1)
            //ev.target.complete();
        }
    };

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>{`${t(Collection.Name)} ${t("List View")}`}</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen scrollY={false}>
            <div className="w-full h-full flex flex-col bg-backgound">
                {Collection.Views &&
                    <Collection.Views.ListViewFilter searchValues={searchValues} setSearchValues={setSearchValues} />}
                {filteredRecords.length > 0 && <>
                    <Virtuoso className="ion-content-scroll-host w-full xs:w-96 self-center"
                        style={{ height: '100%' }}
                        data={filteredRecords}
                        endReached={fetchMoreData}
                        itemContent={(index, doc) => {
                            if (Collection.Views) {
                                return <Collection.Views.ListView key={index} Document={doc} CollectionUrl={Collection.url} Editable={Collection.Permissions.Edit} />
                            }
                        }}
                    />
                </>}
                {Collection.Permissions.Create &&
                    <Link to={"/" + Collection.url + "/" + v4() + "/edit"}>
                        <IonFab vertical="bottom" horizontal="end" slot="fixed">
                            <IonFabButton color="primary"  >
                                <IonIcon icon={add} />
                            </IonFabButton>
                        </IonFab>
                    </Link>
                }
            </div>
        </IonContent>
    </IonPage >
}

export default ListView