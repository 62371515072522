import { ViewListIcon } from '@heroicons/react/outline'
import ListView, { filterListView, ListViewFilter } from "./ListView"
import EditView from "./EditView"
import DetailView from "./DetailView"
import { Modulo } from '../..'

export const AOS_Products_Quotes: Modulo = {
    Name: "AOS_Products_Quotes",
    url: "aos_products_quotes",
    Icon: ViewListIcon,
    Schema: {
        id: 'string',
        product_id: 'string',
        name: 'string',
        product_qty: 'integer',
        product_list_price: 'float',
        parent_type: 'string',
        parent_id: 'string',
        deleted: 'boolean',
        assigned_user_id: 'assigned_user_id',

        // sync fields
        new_with_id: 'boolean',
        isSynced: 'boolean',
        lastSynced: 'integer',
    },
    CRMFetch: {
        Fields: ["id", "product_id", "name", "product_qty", "product_list_price", "parent_type", "parent_id", "deleted"],
        Relationships: [],
        Query: (currentUser: string, gloablConfig: any) => ("aos_products_quotes.assigned_user_id = '" + currentUser + "'"),
        OrderBy: "name asc",
        Result2Object: (record: any) => {
            const fields = record['name_value_list']
            let name = (fields['name']['value']?.charAt(0)?.toUpperCase() + fields['name']['value']?.slice(1, fields['name']['value'].length)) || ""
            return {
                id: record['id'],
                name: name,
                product_id: fields['product_id']['value'],
                product_qty: fields['product_qty']['value'],
                product_list_price: fields['product_list_price']['value'],
                parent_type: fields['parent_type']['value'],
                parent_id: fields['parent_id']['value'],
                deleted: fields['deleted']['value'],

                isSynced: true,
                lastSynced: Date.now(),
            }
        }
    },
    Views: {
        ListView: ListView,
        ListViewFilter: ListViewFilter,
        EditView: EditView,
        DetailView: DetailView
    },
    Methods: {
        filterListView: filterListView
    },
    Permissions: {
        Edit: true,
        Create: true
    },
    ShowMenu: false,
    SelectLists: {}
}
