import { SQLiteDBConnection } from "react-sqlite-hook";
import SearchBar from "../../../components/Search";
import { FitlerProps, ListViewComponentProps } from "../../../pages/ListView";
import { AOS_Products_Quotes } from "./AOS_Products_Quotes";

export const filterListView = async (db: SQLiteDBConnection, search: { [key: string]: string }, page: number, pageSize: number) => {

    await db.open()

    const query = `SELECT * FROM ${AOS_Products_Quotes.Name} WHERE name LIKE '%${search.search || ""}%' COLLATE NOCASE ORDER BY name LIMIT ${pageSize} OFFSET ${page * pageSize}`;
    const res: any = await db.query(query)

    return res.values
}


export const ListViewFilter: React.FC<FitlerProps> = ({ searchValues, setSearchValues }) => {

    const setSearchBar: React.Dispatch<React.SetStateAction<any>> = (newValue: string) => {
        setSearchValues(prevState => ({
            ...prevState,    // keep all other key-value pairs
            search: newValue // update the value of specific key
        }))
    }

    return (
        <SearchBar searchValue={searchValues.search || ""} setSearchValue={setSearchBar} className={"mt-1 relative w-full max-w-sm self-center"} ></SearchBar>
    )
}


const ListView: React.FC<ListViewComponentProps> = ({ Document, CollectionUrl, Editable }) => {
    return (<div className="w-full flex justify-between shrink-0 h-10 bg-white mb-1 rounded-md p-2">
        <div className="w-2/5 shrink-0 whitespace-nowrap overflow-hidden">
            <div className="">{Document.name}</div>
        </div>
        <div className="w-1/5 font-bold text-sm ml-2">$<span className="text-base">{Document.product_list_price}</span></div>
        <div className="w-1/5  ml-2">{Document.product_qty}</div>
        <div className="w-1/5 font-bold text-sm text-left  ml-2">$<span className="text-base">{Document.product_list_price * Document.product_qty}</span></div>
    </div>)
}

export default ListView