import { BriefcaseIcon } from "@heroicons/react/outline";
import ListView, { filterListView, ListViewFilter } from "./ListView";
import EditView from "./EditView";
import DetailView from "./DetailView";
import { Modulo } from "../..";

export const Meetings: Modulo = {
  Name: "Meetings",
  url: "meetings",
  Icon: BriefcaseIcon,
  Schema: {
    id: "string",
    name: "string",
    status: "string",
    date_start: "string",
    date_end: "string",
    duration: "string",
    parent_name: "string",
    parent_id: "string",
    assigned_user_id: "string",
    parent_type: "string",
    description: "string",

    // sync fields
    new_with_id: "boolean",
    isSynced: "boolean",
    lastSynced: "integer",
  },
  CRMFetch: {
    Fields: [
      "name",
      "status",
      "date_start",
      "date_end",
      "duration",
      "parent_name",
      "parent_id",
      "assigned_user_id",
      "parent_type",
      "description",
    ],
    Relationships:
      [] /*accounts_cstm.activo_c=1 AND accounts.assigned_user_id = '" + currentUser + "'*/,
    Query: (currentUser: string, gloablConfig: any) => "",
    OrderBy: "name asc",
    Result2Object: (account: any) => {
      const fields = account["name_value_list"];
      let name =
        fields["name"]["value"]?.charAt(0)?.toUpperCase() +
          fields["name"]["value"]?.slice(1, fields["name"]["value"].length) ||
        "";
      return {
        id: account["id"],
        name: name,
        status: fields["status"]["value"],
        date_start: fields["date_start"]["value"],
        date_end: fields["date_end"]["value"],
        duration: fields["duration"]["value"],
        parent_type: fields["parent_type"]["value"],
        parent_name: fields["parent_name"]["value"],
        parent_id: fields["parent_id"]["value"],
        assigned_user_id: fields["assigned_user_id"]["value"],
        description: fields["description"]["value"],

        isSynced: true,
        lastSynced: Date.now(),
      };
    },
  },
  Views: {
    ListView: ListView,
    ListViewFilter: ListViewFilter,
    EditView: EditView,
    DetailView: DetailView,
  },
  Methods: {
    filterListView: filterListView,
  },
  Permissions: {
    Edit: true,
    Create: true,
  },
  ShowMenu: true,
  SelectLists: {
    status: [
      { value: "Planned", label: "Planificada" },
      { value: "Held", label: "Realizada" },
      { value: "Not Held", label: "No Realizada" },
    ],
  },
};
