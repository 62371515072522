import { PencilIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { SQLiteDBConnection } from "react-sqlite-hook";
import SearchBar from "../../../components/Search";
import { FitlerProps, ListViewComponentProps } from "../../../pages/ListView";
import { Accounts } from "./Accounts";

export const filterListView = async (db: SQLiteDBConnection, search: { [key: string]: string }, page: number, pageSize: number) => {

    await db.open()

    const query = `SELECT * FROM ${Accounts.Name} WHERE name LIKE '%${search.search || ""}%' COLLATE NOCASE ORDER BY name LIMIT ${pageSize} OFFSET ${page * pageSize}`;
    const res: any = await db.query(query)

    return res.values
}


export const ListViewFilter: React.FC<FitlerProps> = ({ searchValues, setSearchValues }) => {

    const setSearchBar: React.Dispatch<React.SetStateAction<any>> = (newValue: string) => {
        setSearchValues(prevState => ({
            ...prevState,    // keep all other key-value pairs
            search: newValue // update the value of specific key
        }))
    }

    return (
        <SearchBar searchValue={searchValues.search || ""} setSearchValue={setSearchBar} className={"mt-1 relative w-full max-w-sm self-center"} ></SearchBar>
    )
}


const ListView: React.FC<ListViewComponentProps> = ({ Document, CollectionUrl, Editable }) => {
    return (<div className="w-full flex justify-between shrink-0 h-10 bg-white mb-1 rounded-md">
        <Link className="h-fit w-full p-2 overflow-hidden whitespace-nowrap truncate " to={{
            pathname: "/" + CollectionUrl + "/" + (Document?.id || ""),
            state: Document
        }}>
            <p className="" >{Document?.name}</p>
        </Link>
        {Editable &&
            <Link className="p-2" to={{
                pathname: "/" + CollectionUrl + "/" + (Document?.id || "") + "/edit",
                state: Document,
            }}>
                <PencilIcon className="h-5 w-5 self-center stroke-primary" />
            </Link>
        }
    </div>)
}

export default ListView