import { DetailViewComponentProps } from "../../../pages/DetailView";
import sin_imagen from '../../../sin_imagen.jpg';

const DetailView: React.FC<DetailViewComponentProps> = ({ Document }) => {
    return (<div className="w-full flex justify-start shrink-0 h-32 bg-white mb-1 rounded-md p-2">
        <div className="flex flex-col justify-between shrink-0">
            <img src={Document.url || sin_imagen} className="h-20 w-auto self-center" alt={Document.nombre_c}></img>
            <div className="self-center font-semibold">Qty: {Document.product_qty}</div>
        </div>
        <div className="flex flex-col ml-2 justify-between">
            <div className="">{Document.name} - {Document.nombre_c}</div>
            <div className="font-bold text-sm">$<span className="text-lg">{Document.product_list_price * Document.product_qty}</span></div>
            <div className="">Stock: {Document.stock_c}</div>
        </div>
    </div>)
}

export default DetailView