import { useCallback, useEffect, useState } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { sqlite } from "../App"
import { GlobalConfig, Modulo } from "../config"
import { SQLiteDBConnection } from "react-sqlite-hook"
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react"
import { Capacitor } from "@capacitor/core"
import { v4 } from 'uuid';
import { useTranslation } from "react-i18next"

export declare type EditViewComponentProps = {
    Document: { [key: string]: any },
    setRecord: React.Dispatch<React.SetStateAction<{
        [key: string]: any;
    } | null>>,
    handleSubmit: (validate: Function, hook?: Function | undefined) => Promise<void>
}

declare type Props = {
    Collection: Modulo
}

const EditView: React.FC<Props> = ({ Collection }) => {
    const { t } = useTranslation();
    const platform = Capacitor.getPlatform();

    let match = useRouteMatch<any>("/" + Collection.url + "/:id");

    const location = useLocation<any>()
    const history = useHistory<any>()

    const [present] = useIonToast();

    const [record, setRecord] = useState<{ [key: string]: any } | null>(location.state?.record || null)
    const [db, setDB] = useState<SQLiteDBConnection | null>(null)

    const searchDoc = useCallback(async () => {
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        let dbConn: SQLiteDBConnection
        if (ret.result && isConn) {
            dbConn = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            dbConn = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
        }

        await dbConn.open()

        if (/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(match?.params?.id)) {
            const query = `SELECT * FROM ${Collection.Name} where id='${match?.params?.id || "-13142142"}' `
            let res = await dbConn.query(query)

            if (res.values?.length === 1) {
                setRecord(res.values[0])
            } else {
                setRecord({
                    id: match?.params?.id,
                    isSynced: false,
                    new_with_id: false
                })
            }
        } else {
            setRecord({
                id: v4(),
                isSynced: false,
                new_with_id: false
            })
        }

        setDB(dbConn)
    }, [Collection, match?.params?.id])

    useEffect(() => {
        if (!record) {
            searchDoc()
        }
    }, [searchDoc, record, match])

    const handleSubmit = async (validate: Function, hook?: Function) => {

        if (record) {
            let check = validate()
            if (check) {
                present({
                    position: 'top',
                    message: "Error with record: " + check.message,
                    duration: 4000,
                    color: "danger"
                })
                return
            }
            record.isSynced = false
            let dbConn: SQLiteDBConnection
            if (!db) {
                const ret = await sqlite.checkConnectionsConsistency();
                const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;

                if (ret.result && isConn) {
                    dbConn = await sqlite.retrieveConnection(GlobalConfig.db);
                } else {
                    dbConn = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
                }
            } else {
                dbConn = db
            }
            let campos = Object.keys(record).join()
            let values = "'" + Object.values(record).join("','") + "'"

            let querys = [{ statement: `INSERT OR REPLACE INTO ${Collection.Name}(${campos}) VALUES(${values});`, values: [] }];

            if (hook) {
                querys.push(...hook())
            }

            await dbConn.open()

            try {
                await dbConn.executeSet(querys);

                if (platform === 'web') {
                    // save the db to store
                    await sqlite.saveToStore(GlobalConfig.db);
                }
            } catch (e) {
                present({
                    position: 'top',
                    message: Collection.Name + ": error " + e,
                    duration: 4000,
                    color: "danger"
                })
            }

            await dbConn.close()

            history.replace(
                {
                    pathname: "/" + Collection.url + "/" + record?.id,
                    state: record,
                }
            )
        } else {
            present({
                position: 'top',
                message: Collection.Name + ": error with record: " + record,
                duration: 4000,
                color: "danger"
            })
        }

        //navigate("/" + Collection.name + "/" + record["id"]);
    }

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>{`${t(Collection.Name)} ${t("Edit View")}`}</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen >
            <div className="w-full h-full flex flex-col bg-backgound">
                {record && Collection.Views && <Collection.Views.EditView Document={record} setRecord={setRecord} handleSubmit={handleSubmit} />}
            </div>
        </IonContent>
    </IonPage>

}

export default EditView