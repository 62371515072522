import { Link } from "react-router-dom";
import EditFab from "../../../components/EditFab";
import { DetailViewComponentProps } from "../../../pages/DetailView";
import { AOS_Products } from "./AOS_Products";


const DetailView: React.FC<DetailViewComponentProps> = ({ Document }) => {
    return <>
        <div className="w-full xs:w-96 flex flex-col self-center px-2 relative h-fit" >
            <div className="w-full h-10 m-2 self-center">

            </div>
        </div>
        {AOS_Products.Permissions.Edit &&
            <Link to={{
                pathname: "/" + AOS_Products.url + "/" + Document.id + "/edit",
                state: { record: Document },
            }}>
                <EditFab />
            </Link>
        }
    </>
}

export default DetailView