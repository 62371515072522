import { useState } from "react"
import { useAuth } from "../Hooks/useAuthentication"
import { ReactComponent as Logo } from '../logo.svg'
import bg from '../bg.png';
import md5 from 'md5'
import { IonContent, IonPage } from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Login: React.FC<any> = () => {
    const { t } = useTranslation();
    let history = useHistory<any>();
    const location = useLocation<any>();

    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [logging, setLoggin] = useState(false)

    const AuthContext = useAuth()

    const login = async () => {
        if (!logging) {
            setLoggin(true)
            await AuthContext?.onLogin(history, userName, md5(password), location.state || "").catch(e => { setLoggin(false); setError(String(e)) })
        }
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="h-full w-full flex justify-center bg-fixed bg-right-bottom bg-contain bg-no-repeat bg-backgound" style={{
                    backgroundImage: `url(${bg})`
                }}>
                    <div className="flex flex-col self-center relative bg-backgound bg-opacity-75 rounded-md md:text-2xl xl:text-xl">

                        <Logo className="absolute -top-40 md:-top-48 xl:-top-44" />
                        <h1 className="text-4xl font-extrabold">{t("Sign In")}</h1>
                        <h1 className="text-lg font-boldtext-secondary">{t('Sign In to continue')}</h1>

                        <input className="mt-3 py-1 px-2 rounded-md bg-white" placeholder={t("User")} value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                        <input type={"password"} className="mt-3 py-1 px-2 rounded-md bg-white" placeholder={t("Password")} value={password} onChange={(e) => { setPassword(e.target.value) }} />

                        {error &&
                            <p className="text-font-alert font-semibold text-sm">{error}</p>
                        }
                        <button className="mt-3 p-1 rounded-md bg-primary text-font-primary flex justify-center" type="button" onClick={() => login()}>
                            {logging ?
                                <div className="animate-spin border-4 h-7 w-7 rounded-full border-t-secondary border-backgound self-center"></div> :
                                t("Sign In")}
                        </button>
                    </div>

                </div >
            </IonContent>
        </IonPage>
    )
}

export default Login

