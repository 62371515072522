import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { Virtuoso } from "react-virtuoso";
import { GlobalConfig } from "../..";
import { sqlite } from "../../../App";
import EditFab from "../../../components/EditFab";
import Date from "../../../components/Fields/Date";
import Input from "../../../components/Fields/Input";
import Select from "../../../components/Fields/Select";
import { DetailViewComponentProps } from "../../../pages/DetailView";
import { Accounts } from "../Accounts/Accounts";
import { AOS_Products } from "../AOS_Products/AOS_Products";
import { AOS_Products_Quotes as AOSProductsQuotes } from "../AOS_Products_Quotes/AOS_Products_Quotes";
import { AOS_Quotes } from "./AOS_Quotes";


const DetailView: React.FC<DetailViewComponentProps> = ({ Document }) => {
    const [lineItems, setLineItems] = useState<any[]>([])

    const getLineItems = useCallback(async () => {
        // retrieve the connections
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        var db: SQLiteDBConnection
        if (ret.result && isConn) {
            db = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            db = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
        }

        // must open connection
        await db.open();

        //const productsFields = `${AOS_Products.Name}.stock_c, ${AOS_Products.Name}.nombre_c`

        const lines = await db.query(`SELECT ${AOSProductsQuotes.Name}.* FROM ${AOSProductsQuotes.Name} 
        JOIN ${AOS_Products.Name} ON ${AOSProductsQuotes.Name}.product_id = ${AOS_Products.Name}.id
        WHERE parent_id='${Document.id}'`)
        //console.log(lines)
        setLineItems(lines.values || [])
    }, [Document.id])

    useEffect(() => {
        getLineItems()
    }, [getLineItems])


    return <>
        <div className="w-full xs:w-96 flex flex-col self-center px-2 relative h-full" >
            <div className="w-full h-10 m-2 self-center">
                <Input record={Document} id={"name"} label={"AOS_Quotes_name"} readonly />
            </div>
            <div className="w-full h-10 m-2 self-center">
                {Document.billing_account_id !== "" ?
                    <Link to={`/${Accounts.url}/${Document.billing_account_id}`}>
                        <Input record={Document} id={"billing_account"} label={"Accounts"} readonly />
                    </Link>
                    :
                    <Input record={Document} id={"billing_account"} label={"Accounts"} readonly />
                }
            </div>
            <div className="w-full h-10 m-2 self-center flex space-x-1">
                <div className="w-1/2">
                    <Input record={Document} id={"number"} label={"AOS_Quotes_number"} readonly />
                </div>
                <div className="w-1/2">
                    <Select record={Document} id={"stage"} label={"AOS_Quotes_stage"} options={AOS_Quotes.SelectLists.stage} readonly />
                </div>
            </div>
            <div className="w-full h-10 m-2 self-center flex space-x-1">
                <div className="w-1/2">
                    <Date record={Document} id={"expiration"} label={"AOS_Quotes_expiration"} readonly />
                </div>
                <div className="w-1/2">
                    <Select record={Document} id={"invoice_status"} label={"AOS_Quotes_invoice_status"} options={AOS_Quotes.SelectLists.statusFact} readonly />
                </div>
            </div>
            <div className="w-full h-10 m-2 self-center flex space-x-1">
                <div className="w-1/2">
                    <Select record={Document} id={"approval_status"} label={"AOS_Quotes_approval_status"} options={AOS_Quotes.SelectLists.statusApprov} readonly />
                </div>
                <div className="w-1/2">
                    <Select record={Document} id={"term"} label={"AOS_Quotes_term"} options={AOS_Quotes.SelectLists.term} readonly />
                </div>
            </div>
            <div className="w-full h-10 m-2 self-center flex">
                <div className="w-2/5 shrink-0">
                    <Input record={{}} id={""} label={"Product"} readonly />
                </div>
                <div className="w-1/5 shrink-0">
                    <Input record={{}} id={""} label={"Price"} readonly />
                </div>
                <div className="w-1/5 shrink-0">
                    <Input record={{}} id={""} label={"Qty"} readonly />
                </div>
                <div className="w-1/5 shrink-0">
                    <Input record={{}} id={""} label={"Total"} readonly />
                </div>
            </div>
            <Virtuoso className="ion-content-scroll-host w-full xs:w-96 self-center"
                style={{ height: '100%' }}
                data={lineItems}
                itemContent={(index, doc) => {
                    return <AOSProductsQuotes.Views.ListView key={index} Document={doc} />
                }}
            />
            <div className="w-full h-10 m-2 self-center flex">
                <div className="w-32">
                    <Input record={{}} id={""} label={"AOS_total_amount"} readonly />
                </div>
                <div className="w-full">
                    <Input record={Document} id={"total_amount"} label={""} readonly />
                </div>
            </div>
        </div>
        {AOSProductsQuotes.Permissions.Edit &&
            <Link to={{
                pathname: "/" + AOS_Quotes.url + "/" + Document.id + "/edit",
                state: { record: Document },
            }}>
                <EditFab />
            </Link>
        }
    </>
}

export default DetailView