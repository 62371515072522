import { PencilIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { SQLiteDBConnection } from "react-sqlite-hook";
import SearchBar from "../../../components/Search";
import { FitlerProps, ListViewComponentProps } from "../../../pages/ListView";
import { Meetings } from "./Meetings";

export const filterListView = async (
  db: SQLiteDBConnection,
  search: { [key: string]: string },
  page: number,
  pageSize: number
) => {
  await db.open();

  const query = `SELECT * FROM ${Meetings.Name} 
    WHERE (name LIKE '%${
      search.search || ""
    }%' COLLATE NOCASE OR parent_name LIKE '%${
    search.search || ""
  }%' COLLATE NOCASE) 
    ${search?.parent_id ? `AND parent_id = '${search.parent_id}'` : ""}
    ORDER BY date_start LIMIT ${pageSize} OFFSET ${page * pageSize}`;
  const res: any = await db.query(query);

  return res.values;
};

export const ListViewFilter: React.FC<FitlerProps> = ({
  searchValues,
  setSearchValues,
}) => {
  const setSearchBar: React.Dispatch<React.SetStateAction<any>> = (
    newValue: string
  ) => {
    setSearchValues((prevState) => ({
      ...prevState, // keep all other key-value pairs
      search: newValue, // update the value of specific key
    }));
  };

  return (
    <SearchBar
      searchValue={searchValues.search || ""}
      setSearchValue={setSearchBar}
      className={"mt-1 relative w-full max-w-sm self-center"}
    ></SearchBar>
  );
};

const ListView: React.FC<ListViewComponentProps> = ({
  Document,
  CollectionUrl,
  Editable,
}) => {
  return (
    <div className="w-full flex flex-wrap justify-between shrink-0 h-fit bg-white mb-1 rounded-md py-1">
      <Link
        className="h-fit w-11/12 px-2 overflow-hidden whitespace-nowrap truncate flex flex-wrap justify-between shrink-0"
        to={{
          pathname: "/" + CollectionUrl + "/" + (Document?.id || ""),
          state: Document,
        }}
      >
        <p className="w-9/12 whitespace-nowrap overflow-hidden text-lg font-bold">
          {Document?.name}
        </p>
        <p className="w-9/12 whitespace-nowrap overflow-hidden text-lg font-bold">
          {Document?.description}
        </p>
        <p className="w-9/12 whitespace-nowrap overflow-hidden text-lg">
          <p className="w-3/12 self-center whitespace-nowrap">
            {Document?.date_start &&
              Document?.date_start !== "" &&
              dayjs(Document?.date_start).format("DD/MM HH:mm")}
          </p>
        </p>
        <p className="w-8/12 whitespace-nowrap overflow-hidden ">
          {Document.parent_name}
        </p>
        <p className="w-4/12">Direccion</p>
      </Link>
      {Editable && (
        <Link
          className="w-1/12 px-2"
          to={{
            pathname:
              "/" + CollectionUrl + "/" + (Document?.id || "") + "/edit",
            state: Document,
          }}
        >
          <PencilIcon className="h-5 w-5 self-center stroke-primary" />
        </Link>
      )}
    </div>
  );
};

export default ListView;
