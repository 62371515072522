import { IonSplitPane } from '@ionic/react';
import {
    Redirect,
    useLocation,
} from 'react-router-dom';
import { useAuth } from '../Hooks/useAuthentication';
import Menu from './Menu';

declare type Props = {
    children: JSX.Element,
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
    const AuthContext = useAuth();
    const location = useLocation();

    if (!AuthContext?.token) {
        return <Redirect to={
            {
                pathname: "/login",
                state: location.pathname
            }} />;
    }

    return (
        <IonSplitPane contentId="main">
            <Menu />
            {children}
        </IonSplitPane>
    )
        ;
};

export default ProtectedRoute