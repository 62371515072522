import { useCallback, useEffect, useRef, useState } from "react"
import { useAuth } from "../Hooks/useAuthentication"
import { get_entries_count, get_entry_list, set_entry } from "../Hooks/useSuiteCRM";
import { ArrowCircleUpIcon, ArrowCircleDownIcon } from "@heroicons/react/outline"
import { GlobalConfig, Modulo } from "../config";
import { sqlite } from "../App";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { useIonToast } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";

declare type Props = {
    Collection: Modulo
    collectionIndex: number
    syncing: boolean
    uploading: boolean
    dowloading: boolean
    syncIndex: number

    setSyncIndex: React.Dispatch<React.SetStateAction<number>>
    setError: React.Dispatch<React.SetStateAction<boolean>>
}


const ModuleStatus: React.FC<Props> = ({ Collection, collectionIndex, syncing, uploading, dowloading, syncIndex, setSyncIndex, setError }) => {
    const { t } = useTranslation();

    const platform = Capacitor.getPlatform();
    const AuthContext = useAuth();
    const [present] = useIonToast();

    const myRef = useRef<HTMLDivElement>(null)

    const [syncedRecords, setSyncedRecords] = useState(0)
    const [newRecords, setNewRecords] = useState(0)
    const [avanceSync, setAvanceSync] = useState(0)


    let sincronizandoCollection = collectionIndex === syncIndex
    let subiendoCollection = sincronizandoCollection && uploading
    let descargandoCollection = sincronizandoCollection && !subiendoCollection && dowloading

    const getCount = useCallback(async () => {
        // retrieve the connections
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        var db: SQLiteDBConnection
        if (ret.result && isConn) {
            db = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            try {
                db = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
            } catch {
                db = await sqlite.retrieveConnection(GlobalConfig.db);
            }
        }

        // must open connection
        await db.open();

        const records = await db.query(`SELECT count(1) as synced FROM ${Collection.Name} WHERE isSynced='true'`).catch(e => { console.error(Collection.Name + e) })
        const records2 = await db.query(`SELECT count(1) as not_synced FROM ${Collection.Name} WHERE isSynced='false'`).catch(e => { console.error(Collection.Name + e) })

        setSyncedRecords(records?.values ? records?.values[0].synced : 0)
        setNewRecords(records2?.values ? records2?.values[0].not_synced : 0)
    }, [Collection])

    useEffect(() => {
        getCount()
    }, [getCount])

    const download = useCallback(async () => {
        const startTime = Date.now();
        try {
            const session = JSON.parse(AuthContext?.token || "")

            const sessionID = session["id"]
            const userID = session["name_value_list"]['user_id']['value']
            const maxResults = 100

            const count = await get_entries_count(
                sessionID, Collection.Name,
                Collection.CRMFetch.Query(userID, GlobalConfig),
                false
            )

            if (count?.name) {
                throw (count?.name)
            }

            const resultCount = parseInt(count.result_count)
            setSyncedRecords(resultCount || 0)

            let advance = 0
            let pulledRecords = 0
            setAvanceSync(advance)

            const promises = [];
            const querys = []

            let error = false
            let errorMsg = ""

            /* eslint-disable */
            for (var offset = 0; offset <= Math.floor(resultCount / maxResults); offset++) {
                promises.push(
                    get_entry_list(sessionID,
                        Collection.Name,
                        Collection.CRMFetch.Query(userID, GlobalConfig),
                        Collection.CRMFetch.OrderBy, offset * maxResults,
                        Collection.CRMFetch.Fields,
                        Collection.CRMFetch.Relationships,
                        maxResults, false)
                        .then((response) => {

                            if (!error && response?.name) {
                                error = true
                                errorMsg = Collection.Name + ": " + response?.name
                            }

                            if (error) return

                            response.entry_list.forEach((record: any) => {


                                let parsed = Collection.CRMFetch.Result2Object(record)

                                let campos = Object.keys(parsed).join()
                                let values = "'" + Object.values(parsed).join("','") + "'"

                                let query = `INSERT OR REPLACE INTO ${Collection.Name}(${campos}) VALUES(${values});`;
                                querys.push({ statement: query, values: [] });
                            });

                            if (advance < 100) {
                                pulledRecords += response.result_count
                                advance = (Math.round((pulledRecords / resultCount) * 100))
                                setAvanceSync(advance > 100 ? 100 : advance)
                            }

                        })
                )
            }
            /* eslint-enable */

            await Promise.all(promises).catch(e => { error = true; errorMsg = String(e) }); // wait until all promises finished

            if (error) {
                throw (errorMsg)
            }

            // retrieve the connections
            const ret = await sqlite.checkConnectionsConsistency();
            const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
            var db: SQLiteDBConnection
            if (ret.result && isConn) {
                db = await sqlite.retrieveConnection(GlobalConfig.db);
            } else {
                db = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
            }

            // must open connection
            await db.open();

            // delete records in db
            let query = `DELETE FROM ${Collection.Name} WHERE (lastSynced < '${startTime}' OR lastSynced IS NULL OR lastSynced = '') AND isSynced='true' ;`
            querys.push({ statement: query, values: [] })

            await db.executeSet(querys);

            if (platform === "web") {
                await sqlite.saveToStore(GlobalConfig.db);
            }
            await db.close();
            await sqlite.closeConnection(GlobalConfig.db);

            setAvanceSync(100)
            setSyncIndex(syncIndex + 1)
        } catch (e) {
            console.log(e)
            present({
                position: 'top',
                message: Collection.Name + ": " + e,
                duration: 4000,
                color: "danger"
            })
            setError(true)
        }
    }, [AuthContext?.token, Collection, setError, setSyncIndex, syncIndex, platform, present])

    const upload = useCallback(async () => {
        const session = JSON.parse(AuthContext?.token || "")

        const sessionID = session["id"]
        //const userID = session["name_value_list"]['user_id']['value']

        let advance = 0
        setAvanceSync(advance)

        let error = false
        let errorMsg = ""
        let record
        let result
        let newSyncedRecords = 0

        // retrieve the connections
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        var db: SQLiteDBConnection
        if (ret.result && isConn) {
            db = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            db = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
        }

        /* eslint-disable */
        let query = `SELECT * FROM ${Collection.Name} WHERE isSynced='false' LIMIT 1;`
        for (let i = 0; i < newRecords; i++) {
            // must open connection
            await db.open();

            result = await db.query(query)
            if (typeof result.values === 'undefined' || result.values?.length === 0) break
            record = result.values[0]

            await set_entry(sessionID, Collection.Name, record).then((response) => {
                if (response?.name) {
                    console.error(response)
                    errorMsg = response?.name
                    error = true
                    return
                }

                if (advance < 100) {
                    advance += (Math.round((response.result_count / newRecords) * 100))
                    setAvanceSync(advance > 100 ? 100 : advance)
                }

            }).catch(e => { error = true; errorMsg = String(e); return })

            if (error) {
                present(Collection.Name + ": " + errorMsg)
                setError(true)
                return
            }
            if (record.id) { }
            let querySetSynced = `DELETE FROM ${Collection.Name} WHERE id='${record.id}'`
            await db.query(querySetSynced)

            await db.close();
            newSyncedRecords++
            if (error) {
                present(Collection.Name + ": " + errorMsg)
                setError(true)
                return
            }
        }

        /* eslint-enable */
        setSyncIndex((syncIndex) + 1)
        setNewRecords(newRecords - newSyncedRecords)

    }, [AuthContext?.token, Collection, setError, setSyncIndex, syncIndex, newRecords, present])

    useEffect(() => {
        const executeScroll = () => {
            myRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start"
            })
        }

        if (sincronizandoCollection) {
            executeScroll();
        }

        if (syncing) {
            if (sincronizandoCollection) {
                executeScroll();
                if (subiendoCollection) {
                    upload()
                    return
                }

                if (descargandoCollection) {
                    download()
                    return
                }
            }
        }

    }, [syncing, syncIndex, collectionIndex, descargandoCollection, Collection.Name,
        upload, download, setSyncIndex, sincronizandoCollection, subiendoCollection])

    if (!AuthContext?.token) {
        return <>403 Forbidden</>
    }

    return (
        <div ref={myRef} className="w-full bg-white h-20 mt-3 rounded-md px-2 flex relative shrink-0">
            <div className="self-center">
                {<Collection.Icon className="w-11 h-11 stroke-primary" />}
            </div>
            <div className=" self-center flex flex-col ml-2 font-semibold w-full">
                <p>{t(Collection.Name)} </p>
                <div className="w-full flex my-1">
                    <div className="h-2 w-10/12 bg-backgound ring-1 ring-secondary rounded-md self-center" >
                        <div className="h-full bg-secondary transition-all ease-out duration-1000" style={{ width: avanceSync + "%" }}></div>
                    </div>
                    {avanceSync > 0 &&
                        <span className="ml-1 text-sm ">{avanceSync}%</span>
                    }
                </div>
                <span>
                    {!uploading}
                    {descargandoCollection ?
                        ` ${t("Downloading")}: ` : ` ${t("Synchronized")}: `
                    } {syncedRecords}
                    {newRecords > 0 && " |"} <span className="text-font-alert">{newRecords > 0 && ` ${t("New")}: ` + newRecords} </span></span>
            </div>

            {subiendoCollection &&
                <ArrowCircleUpIcon className="h-5 w-5 absolute top-1 right-1 stroke-primary animate-bounce" />}
            {descargandoCollection &&
                <ArrowCircleDownIcon className="h-5 w-5 absolute top-1 right-1 stroke-primary animate-bounce" />}

        </div>
    )
}

export default ModuleStatus