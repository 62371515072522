import { TruckIcon } from '@heroicons/react/outline'
import ListView, { filterListView, ListViewFilter } from "./ListView"
import EditView from "./EditView"
import DetailView from "./DetailView"
import { Modulo } from '../..'

export const AOS_Quotes: Modulo = {
    Name: "AOS_Quotes",
    url: "aosquotes",
    Icon: TruckIcon,
    Schema: {
        id: 'string',
        name: 'string',
        description: 'string',
        number: 'integer',
        stage: 'string',
        expiration: 'string',
        invoice_status: 'string',
        approval_status: 'string',
        term: 'string',
        total_amount: 'float',
        billing_account_id: 'string',
        billing_account: 'string',
        assigned_user_id: 'string',

        // sync fields
        new_with_id: 'boolean',
        isSynced: 'boolean',
        lastSynced: 'integer',
    },
    CRMFetch: {
        Fields: ["id", "name", "description", "number", "stage", "expiration",
            "invoice_status", "approval_status", "term", "total_amount", "billing_account_id", "billing_account"],
        Relationships: [],
        Query: (currentUser: string, gloablConfig: any) => ("aos_quotes.assigned_user_id = '" + currentUser + "'"),
        OrderBy: "name asc",
        Result2Object: (record: any) => {
            const fields = record['name_value_list']
            let name = (fields['name']['value']?.charAt(0)?.toUpperCase() + fields['name']['value']?.slice(1, fields['name']['value'].length)) || ""
            return {
                id: record['id'],
                name: name,
                description: fields['description']['value'],
                number: fields['number']['value'],
                stage: fields['stage']['value'],
                expiration: fields['expiration']['value'],
                invoice_status: fields['invoice_status']['value'],
                approval_status: fields['approval_status']['value'],
                term: fields['term']['value'],
                total_amount: fields["total_amount"]['value'],
                billing_account_id: fields["billing_account_id"]["value"],
                billing_account: fields["billing_account"]["value"],

                isSynced: true,
                lastSynced: Date.now(),
            }
        }
    },
    Views: {
        ListView: ListView,
        ListViewFilter: ListViewFilter,
        EditView: EditView,
        DetailView: DetailView
    },
    Methods: {
        filterListView: filterListView
    },
    Permissions: {
        Edit: true,
        Create: true
    },
    ShowMenu: true,
    SelectLists: {
        term: [
            { value: "Net 15", label: "Red 15" },
            { value: "Net 30", label: "Red 30" },
            { value: "", label: "" },
        ],
        stage: [
            { value: "Draft", label: "Borrador" },
            { value: "Negotiation", label: "Negociación" },
            { value: "Delivered", label: "Enviado" },
            { value: "On Hold", label: "En Espera" },
            { value: "Confirmed", label: "Confirmado" },
            { value: "Closed Accepted", label: "Cerrado Aceptado" },
            { value: "Closed Lost", label: "Perdido" },
            { value: "Cerrado Muerto", label: "Closed Dead" },
        ],
        statusFact: [
            { value: "Not Invoiced", label: "Borrador" },
            { value: "Facturado", label: "Invoiced" },
        ],
        statusApprov: [
            { value: "Approved", label: "Aprobado" },
            { value: "Not Approved", label: "No Aprobado" },
            { value: "", label: "" },
        ]
    }
}
