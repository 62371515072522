import { UserCircleIcon } from "@heroicons/react/outline";
import ListView, { filterListView, ListViewFilter } from "./ListView";
import EditView from "./EditView";
import DetailView from "./DetailView";
import { Modulo } from "../..";

export const Accounts: Modulo = {
  Name: "Accounts",
  url: "accounts",
  Icon: UserCircleIcon,
  Schema: {
    id: "string",
    name: "string",
    phone_office: "string",
    billing_address_street: "billing_address_street",
    jjwg_maps_lat_c: "integer",
    jjwg_maps_lng_c: "integer",
    assigned_user_id: "string",

    // sync fields
    new_with_id: "boolean",
    isSynced: "boolean",
    lastSynced: "integer",
  },
  CRMFetch: {
    Fields: [
      "name",
      "phone_office",
      "billing_address_street",
      "jjwg_maps_lng_c",
      "jjwg_maps_lat_c",
    ],
    Relationships: [] /*accounts_cstm.activo_c=1 AND */,
    Query: (currentUser: string, gloablConfig: any) =>
      "accounts.assigned_user_id = '" + currentUser + "'",
    OrderBy: "name asc",
    Result2Object: (account: any) => {
      const fields = account["name_value_list"];
      let name =
        fields["name"]["value"]?.charAt(0)?.toUpperCase() +
          fields["name"]["value"]?.slice(1, fields["name"]["value"].length) ||
        "";
      return {
        id: account["id"],
        name: name,
        phone_office: fields["phone_office"]["value"],
        billing_address_street: fields["billing_address_street"]["value"],
        jjwg_maps_lat_c: fields["jjwg_maps_lat_c"]["value"],
        jjwg_maps_lng_c: fields["jjwg_maps_lng_c"]["value"],
        isSynced: true,
        lastSynced: Date.now(),
      };
    },
  },
  Views: {
    ListView: ListView,
    ListViewFilter: ListViewFilter,
    EditView: EditView,
    DetailView: DetailView,
  },
  Methods: {
    filterListView: filterListView,
  },
  Permissions: {
    Edit: true,
    Create: true,
  },
  ShowMenu: true,
  SelectLists: {},
};
