import { useCallback, useEffect, useState } from "react"
import { sqlite } from "../App"
import { GlobalConfig, Modulo } from "../config"
import { SQLiteDBConnection } from "react-sqlite-hook"
import { useLocation, useRouteMatch } from "react-router"
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { useTranslation } from "react-i18next"


export declare type DetailViewComponentProps = {
    Document: { [key: string]: any }
}

declare type Props = {
    Collection: Modulo
}

const DetailView: React.FC<Props> = ({ Collection }) => {
    const { t } = useTranslation();

    let match = useRouteMatch<any>("/" + Collection.url + "/:id");
    const location = useLocation<any>()

    const [collectionOG, setColOG] = useState(Collection)
    const [record, setRecord] = useState<{ [key: string]: any } | null>(location.state || null)

    useEffect(() => {
        if (collectionOG !== Collection) {
            if (record !== null) {
                setRecord(null)
            }
            setColOG(Collection)
        }
    }, [Collection, collectionOG, record])

    const searchDoc = useCallback(async () => {
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        let dbConn: SQLiteDBConnection
        if (ret.result && isConn) {
            dbConn = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            dbConn = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
        }

        await dbConn.open()

        const query = `SELECT * FROM ${Collection.Name} where id='${match?.params?.id || "-13142142"}' `
        let res = await dbConn.query(query)

        if (res.values?.length === 1) {
            setRecord(res.values[0])
        }

    }, [Collection, match?.params?.id])

    useEffect(() => {
        if (!record)
            searchDoc()
    }, [searchDoc, record])

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>{`${t(Collection.Name)} ${t("Detail View")}`}</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
            <div className="w-full h-full flex flex-col bg-backgound">
                {collectionOG === Collection && record && Collection.Views && <Collection.Views.DetailView Document={record} />}
            </div>
        </IonContent>
    </IonPage>
}

export default DetailView