import { IonFab, IonFabButton, IonIcon, useIonToast } from "@ionic/react";
import { save } from "ionicons/icons";
import Input from "../../../components/Fields/Input";
import { useAuth } from "../../../Hooks/useAuthentication";
import { EditViewComponentProps } from "../../../pages/EditView";

import { Geolocation } from "@awesome-cordova-plugins/geolocation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const EditView: React.FC<EditViewComponentProps> = ({
  Document,
  setRecord,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const AuthContext = useAuth();
  const session = JSON.parse(AuthContext?.token || "");
  const userID = session["name_value_list"]["user_id"]["value"];

  const [present] = useIonToast();

  // Modificaciones especiales de coleccion
  if (!Document["assigned_user_id"] || Document["assigned_user_id"] === "") {
    Document["assigned_user_id"] = userID;
  }

  const validate = useCallback(() => {
    /* IMPLEMENT */
  }, [Document]);

  const updateLocation = useCallback(async () => {
    try {
      const location = await Geolocation.getCurrentPosition({
        maximumAge: 0,
        timeout: 5000,
        enableHighAccuracy: true,
      });
      setRecord({
        ...Document,
        jjwg_maps_lat_c: location.coords.latitude,
        jjwg_maps_lng_c: location.coords.longitude,
      });
    } catch (e: any) {
      present({
        position: "top",
        message: e.message,
        duration: 4000,
        color: "danger",
      });
      console.log(e);
    }
  }, [Document, present, setRecord]);

  return (
    <>
      <div className="w-full xs:w-96 flex flex-col self-center px-2 h-fit">
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            setRecord={setRecord}
            id={"name"}
            label={"Accounts_name"}
          />
        </div>
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            setRecord={setRecord}
            id={"phone_office"}
            label={"Accounts_phone_office"}
          />
        </div>
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            setRecord={setRecord}
            id={"billing_address_street"}
            label={"Accounts_billing_address_street"}
            type={"tel"}
          />
        </div>
        <div className="w-full h-10 m-2 self-center flex">
          <div className="w-1/2">
            <Input
              record={Document}
              setRecord={setRecord}
              id={"jjwg_maps_lat_c"}
              label={"Accounts_jjwg_maps_lat_c"}
              type={"number"}
            />
          </div>
          <div className="w-1/2">
            <Input
              record={Document}
              setRecord={setRecord}
              id={"jjwg_maps_lng_c"}
              label={"Accounts_jjwg_maps_lng_c"}
              type={"number"}
            />
          </div>
        </div>
        <div className="w-full h-10 m-2 self-center">
          <button
            className="w-full bg-primary text-white p-2 rounded-md"
            onClick={updateLocation}
          >
            {t("Update_coordinates")}
          </button>
        </div>
      </div>
      <IonFab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        onClick={() => handleSubmit(validate)}
      >
        <IonFabButton color="primary">
          <IonIcon icon={save} />
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default EditView;
