import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { Virtuoso } from "react-virtuoso";
import { v4 } from "uuid";
import { GlobalConfig } from "../..";
import { sqlite } from "../../../App";
import EditFab from "../../../components/EditFab";
import Input from "../../../components/Fields/Input";
import { DetailViewComponentProps } from "../../../pages/DetailView";
import { Meetings } from "../Meetings/Meetings";
import { Accounts } from "./Accounts";

const DetailView: React.FC<DetailViewComponentProps> = ({ Document }) => {
  return (
    <>
      <div className="w-full xs:w-96 flex flex-col self-center px-2 relative h-fit">
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            id={"name"}
            label={"Accounts_name"}
            readonly
          />
        </div>
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            id={"billing_address_street"}
            label={"Accounts_billing_address_street"}
            readonly
          />
        </div>
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            id={"phone_office"}
            label={"Accounts_phone_office"}
            type={"tel"}
            readonly
          />
        </div>
        <div className="w-full h-10 m-2 self-center flex">
          <div className="w-1/2">
            <Input
              record={Document}
              id={"jjwg_maps_lat_c"}
              label={"Accounts_jjwg_maps_lat_c"}
              type={"number"}
              readonly
            />
          </div>
          <div className="w-1/2">
            <Input
              record={Document}
              id={"jjwg_maps_lng_c"}
              label={"Accounts_jjwg_maps_lng_c"}
              type={"number"}
              readonly
            />
          </div>
        </div>
        <div className="w-full h-10 m-2 mb-0 self-center flex">
          <div className="w-3/4">
            <Input record={{}} id={""} label={"Meetings"} readonly />
          </div>
          <div className="w-1/4">
            <Input
              record={{}}
              id={""}
              label={"Date"}
              type={"number"}
              readonly
            />
          </div>
        </div>
        <MeetingsList Document={Document} />
        <div className="w-full h-10 m-2 self-center flex">
          <Link
            className="w-full p-2"
            to={{
              pathname: "/" + Meetings.url + "/" + v4() + "/edit",
              state: {
                record: {
                  id: v4(),
                  parent_id: Document.id,
                  parent_name: Document.name,
                  parent_type: Accounts.Name,
                  new_with_id: true,
                },
              },
            }}
          >
            <button className="w-full bg-primary text-white p-2 rounded-md">
              Agregar Reunion
            </button>
          </Link>
        </div>
      </div>
      {Accounts.Permissions.Edit && (
        <Link
          to={{
            pathname: "/" + Accounts.url + "/" + Document.id + "/edit",
            state: { record: Document },
          }}
        >
          <EditFab />
        </Link>
      )}
    </>
  );
};

const MeetingsList: React.FC<any> = ({ Document }) => {
  const pageSize = 20;

  const [page, setPage] = useState(0);
  const [filteredRecords, setFilteredRecords] = useState<
    { [key: string]: any }[]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const [db, setDB] = useState<SQLiteDBConnection | null>(null);

  const initialFilter = useCallback(
    async (page: number, pageSize: number) => {
      if (db) {
        let searchValues = { parent_id: Document.id };

        const records = await Meetings.Methods.filterListView(
          db,
          searchValues,
          page,
          pageSize
        );
        if (records.length === 0) {
          setHasMore(false);
        }
        setFilteredRecords(records);
      }
    },
    [db, Document.id]
  );

  const connectToDB = useCallback(async () => {
    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
    let dbConn: SQLiteDBConnection;
    if (ret.result && isConn) {
      dbConn = await sqlite.retrieveConnection(GlobalConfig.db);
    } else {
      dbConn = await sqlite.createConnection(
        GlobalConfig.db,
        false,
        "no-encryption",
        1
      );
    }
    setDB(dbConn);
  }, []);

  useEffect(() => {
    connectToDB();
  }, [connectToDB]);

  useEffect(() => {
    const reset = 0;
    setPage(reset);
    setHasMore(true);
    initialFilter(reset, pageSize);
  }, [initialFilter]);

  const fetchMoreData = async (ev: any) => {
    if (hasMore && db) {
      const records = await Meetings.Methods.filterListView(
        db,
        { parent_id: Document.id },
        page + 1,
        pageSize
      );
      if (records.length === 0) {
        console.log("no more");
        setHasMore(false);
      }

      setFilteredRecords(filteredRecords.concat(records));
      setPage(page + 1);
      //ev.target.complete();
    }
  };

  return (
    <div className="h-full p-2">
      {filteredRecords.length > 0 && (
        <>
          <div className="h-80">
            <Virtuoso
              className="ion-content-scroll-host w-full self-center"
              style={{ height: "100%" }}
              data={filteredRecords}
              endReached={fetchMoreData}
              itemContent={(index, doc) => {
                return (
                  <Link to={`/${Meetings.url}/${doc?.id}`}>
                    <div className="w-full flex justify-between shrink-0 h-10 bg-white mb-1 rounded-md">
                      <p className="self-center overflow-hidden">{doc?.name}</p>
                      <p className="self-center whitespace-nowrap">
                        {doc?.date_start &&
                          doc?.date_start !== "" &&
                          dayjs(doc?.date_start).format("DD/MM HH:mm")}
                      </p>
                    </div>
                  </Link>
                );
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DetailView;
