import { IonDatetime } from "@ionic/react"
import { useCallback, useRef, useState } from "react"

import dayjs from 'dayjs'
import { useTranslation } from "react-i18next"

declare type Props = {
    record: { [key: string]: any }
    setRecord?: React.Dispatch<React.SetStateAction<{
        [key: string]: any;
    } | null>>
    id: string
    label?: string
    readonly?: boolean,
    min?: string,
    max?: string
}

const DateTime: React.FC<Props> = ({ record, setRecord, id, label, readonly, min, max }) => {
    const { t } = useTranslation();

    const validDate = typeof record[id] != 'undefined' && dayjs(record[id], 'YYYY-MM-DD HH:mm:ss', true).isValid()
    const myInput = useRef<any>()

    const [currentValue, setValue] = useState(validDate ? dayjs(record[id]).add(dayjs(record[id]).utcOffset() * 1000 * 60).toISOString() : '')
    const [showing, setShowing] = useState(false)

    const validMinDate = typeof min != 'undefined' && dayjs(min, 'YYYY-MM-DD HH:mm:ss', true).isValid()
    const minDate = validMinDate ? dayjs(min).add(dayjs().utcOffset() * 1000 * 60).toISOString() : '2020-01-01'

    const validMaxDate = typeof max != 'undefined' && dayjs(max, 'YYYY-MM-DD HH:mm:ss', true).isValid()
    const maxDate = validMaxDate ? dayjs(max).add(dayjs().utcOffset() * 1000 * 60).toISOString() : (dayjs().year() + 2).toFixed(0) + '-12-31'

    const setValueAux = useCallback((value) => {
        if (readonly) return
        if (setRecord) {
            setRecord({
                ...record, [id]: dayjs(value).format('YYYY-MM-DD HH:mm:ss')
            })
        }
        setValue(value)
    }, [record, setRecord, readonly, id])

    return <>
        {!readonly && showing &&
            <div className="inline">
                <div className="h-full w-full bg-backgound absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-80 z-40 " onClick={(e) => { setShowing(false); }}>
                </div>
                <div className="h-fit w-fit absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40">
                    <div className="h-max shadow-md shadow-black overflow-hidden">
                        < IonDatetime min={minDate} max={maxDate} value={currentValue} onIonChange={(e: any) => { setValueAux(e.detail.value) }} hour-cycle="h12"></IonDatetime>
                    </div>
                </div>

            </div>
        }

        <div className="h-full w-full relative">
            <input ref={myInput} className={`appearance-none pt-4 pb-1 px-2 h-full w-full focus:outline-none bg-white
    border-b-primary border-b-2 text-lg font-semibold font-sans
    `} readOnly={true} value={validDate ? dayjs(record[id]).format('DD/MM/YYYY HH:mm') : ""} onFocus={() => setShowing(true)}></input>
            <label onClick={() => { myInput.current?.focus() }} className={`absolute text-primary font-bold transition-all duration-100 left-2
        ${validDate ? "text-sm -top-1" : "text-md top-3"} `}>{t(label || "")}</label>
        </div >
    </>
}

export default DateTime