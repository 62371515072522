import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import MyMap from "../components/Map"

const MapView: React.FC<any> = () => {
    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>{`Mapa`}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <div className="w-full h-full">
                <MyMap />
            </div>
        </IonContent>
    </IonPage >
}

export default MapView