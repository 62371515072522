
import { PlusIcon, MinusIcon } from '@heroicons/react/outline'
import { useCallback, useEffect, useState } from 'react';
import { SQLiteDBConnection } from 'react-sqlite-hook';
import { v4 } from 'uuid';
import { GlobalConfig } from '../..';
import { sqlite } from '../../../App';
import { useAuth } from '../../../Hooks/useAuthentication';

import { EditViewComponentProps } from "../../../pages/EditView";
import sin_imagen from '../../../sin_imagen.jpg';
import { AOS_Products } from '../AOS_Products/AOS_Products';


interface APQEditView extends EditViewComponentProps {
    lineItems: Map<string, { [key: string]: any }>
    setLineItems: React.Dispatch<React.SetStateAction<Map<string, { [key: string]: any; }>>>
    newLineItems: Map<string, { [key: string]: any }>
    setNewLineItems: React.Dispatch<React.SetStateAction<Map<string, { [key: string]: any; }>>>
    parent: { [key: string]: any }
}

const EditView: React.FC<APQEditView> = ({
    Document, handleSubmit, lineItems, setLineItems,
    newLineItems, setNewLineItems, parent, setRecord }) => {

    const AuthContext = useAuth()
    const session = JSON.parse(AuthContext?.token || "")
    const userID = session["name_value_list"]['user_id']['value']

    // products fields
    const [stock, setStock] = useState(Document.stock_c)
    const [nombreC, setNombreC] = useState(Document.nombre_c)
    const [img, setImg] = useState(Document.url)

    const lineItem = lineItems.get(Document.product_id || Document.id)
    if (lineItem) {
        Document.product_qty = lineItem.product_qty - 0
    }

    const newLineItem = newLineItems.get(Document.product_id || Document.id)
    if (newLineItem) {
        Document.product_qty = newLineItem.product_qty - 0
    }

    const getProductInfo = useCallback(async () => {
        if (typeof stock === 'undefined' || typeof nombreC === 'undefined') {
            // retrieve the connections
            const ret = await sqlite.checkConnectionsConsistency();
            const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
            var db: SQLiteDBConnection
            if (ret.result && isConn) {
                db = await sqlite.retrieveConnection(GlobalConfig.db);
            } else {
                db = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
            }

            // must open connection
            await db.open();

            const products = await db.query(`SELECT * FROM ${AOS_Products.Name} WHERE id='${Document.product_id}' LIMIT 1`)

            products?.values?.forEach((product) => {
                //setNombreC(product.nombre_c)
                //setStock(product.stock_c)
                setImg(product.url)
            })
        }

    }, [Document, stock, nombreC])

    useEffect(() => {
        getProductInfo()
    }, [getProductInfo])

    const handleCountChange = (value: number) => {
        const newTotal = (parent.total_amount || 0) + ((value - (Document.product_qty || 0)) * (Document.product_list_price || Document.price || 0))

        setRecord({ ...parent, total_amount: newTotal })
        if (value === 0) {

            Document.product_qty = value
            if (lineItem) {
                lineItem.deleted = true
                lineItem.product_qty = value
                lineItem.isSynced = false

                const newMap = new Map(lineItems)
                newMap.set(lineItem.product_id, lineItem)

                setLineItems(newMap)
            } else if (newLineItem) {
                const newMap = new Map(newLineItems)
                newMap.delete(newLineItem.product_id)

                setNewLineItems(newMap)
            } else {
                alert("INCONSISTENCIA")
            }
        }

        if (value > 0) {
            Document.product_qty = value

            if (lineItem) {
                lineItem.product_qty = value;
                lineItem.isSynced = false;
                lineItem.deleted = false;

                const newMap = new Map(lineItems)
                newMap.set(lineItem.product_id, lineItem)

                setLineItems(newMap)
            } else if (newLineItem) {
                newLineItem.product_qty = value;
                newLineItem.isSynced = false;
                newLineItem.deleted = false;

                const newMap = new Map(newLineItems)
                newMap.set(newLineItem.product_id, newLineItem)

                setNewLineItems(newMap)
            } else {
                // product -> line
                const newLine = {
                    id: v4(),
                    product_id: Document.id,
                    name: Document.name,
                    product_qty: value,
                    product_list_price: Document.price,
                    parent_type: 'AOS_Quotes',
                    parent_id: parent?.id,
                    deleted: false,
                    assigned_user_id: userID,

                    // sync fields
                    new_with_id: true,
                    isSynced: false
                }
                const newMap = new Map(newLineItems)
                newMap.set(newLine.product_id, newLine)

                setNewLineItems(newMap)
            }
        }
    }

    return (<div className="w-full flex justify-start shrink-0 h-32 bg-white mb-1 rounded-md p-2">
        <div className="flex flex-col justify-between shrink-0">
            <img src={img || sin_imagen} className="h-20 w-auto self-center" alt={nombreC}></img>
            <div className='flex justify-around space-x-1'>
                <div><PlusIcon className='h-7 w-7 stroke-primary stroke-[4]' onClick={() => { handleCountChange((Document.product_qty || 0) + 1) }} /></div>
                <input type={"number"} className='text-xl w-10 text-center bg-backgound outline-primary outline-1 shadow-sm shadow-gray-400'
                    value={Number(Document.product_qty).toString() || 0} onChange={(e) => { handleCountChange(Number(e.target.value)) }}>

                </input>
                <div><MinusIcon className='h-7 w-7 stroke-primary stroke-[4]' onClick={() => { handleCountChange(Document.product_qty - 1) }} /></div>
            </div>
        </div>
        <div className="flex flex-col ml-2 justify-between">
            <div className="">{Document.name} - {nombreC}</div>
            <div className="font-bold text-sm">$<span className="text-lg">{(Document.product_list_price * Document.product_qty) || Document.price || 0}</span></div>
            <div className="">Stock: {stock}</div>
        </div>
    </div>)
}

export default EditView