import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';
import './Menu.css';
import { Modulos } from '../config';
import { HomeIcon, LogoutIcon, CloudDownloadIcon } from '@heroicons/react/outline';
import { useAuth } from '../Hooks/useAuthentication';
import { useTranslation } from 'react-i18next';

const Menu: React.FC = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const AuthContext = useAuth();
  const newVersionAvailable = localStorage.getItem("new_version")

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Menu</IonListHeader>
          <IonNote>Cerezo Software</IonNote>
          <IonMenuToggle autoHide={false}>
            <IonItem className={location.pathname === "home" ? 'selected' : ''} routerLink={"/home"} routerDirection="none" lines="none" detail={false}>
              <HomeIcon className='w-9 h-9 stroke-primary stroke-1 mr-2' ></HomeIcon>
              <IonLabel>{t('Home')}</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {Modulos.filter((mod) => mod.ShowMenu).map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={"/" + appPage.url} routerDirection="none" lines="none" detail={false}>
                  <appPage.Icon className='w-9 h-9 stroke-primary stroke-1 mr-2' ></appPage.Icon>
                  <IonLabel>{t(appPage.Name)}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}

          {newVersionAvailable &&
            <IonMenuToggle autoHide={false}>
              <IonItem className={`border-t-[1px] border-gray-200 py-2 ${location.pathname === 'update' ? 'selected' : ''}`} routerLink={"/update"} routerDirection="none" lines="none" detail={false}>
                <CloudDownloadIcon className="w-9 h-9 stroke-primary stroke-1 mr-2" />
                <label className='text-red-500 text-xl font-bold'>{t("Update")}!!!</label>
              </IonItem>
            </IonMenuToggle>
          }

          <IonMenuToggle autoHide={false}>
            <IonItem className={`border-t-[1px] border-gray-200 py-2`} lines="none" onClick={() => AuthContext?.onLogout(history)}>
              <LogoutIcon className="w-9 h-9 stroke-primary stroke-1 mr-2" />
              <IonLabel>{t("Sign Out")}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu >
  );
};

export default Menu;
