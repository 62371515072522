import { CubeIcon } from '@heroicons/react/outline'
import ListView, { filterListView, ListViewFilter } from "./ListView"
import EditView from "./EditView"
import DetailView from "./DetailView"
import { Modulo } from '../..'

export const AOS_Products: Modulo = {
    Name: "AOS_Products",
    url: "aosproducts",
    Icon: CubeIcon,
    Schema: {
        id: 'string',
        name: 'string',
        description: 'string',
        price: 'string',
        url: 'string',

        // sync fields
        new_with_id: 'boolean',
        isSynced: 'boolean',
        lastSynced: 'integer',
    },
    CRMFetch: {
        Fields: ["name", "price", "description", "url"],
        Relationships: [],
        Query: (currentUser: string, gloablConfig: any) => (""),
        OrderBy: "name asc",
        Result2Object: (record: any) => {
            const fields = record['name_value_list']
            let name = (fields['name']['value']?.charAt(0)?.toUpperCase() + fields['name']['value']?.slice(1, fields['name']['value'].length)) || ""
            return {
                id: record['id'],
                name: name,
                description: fields['description']['value'],
                price: fields['price']['value'],
                url: fields['url']['value'],
                isSynced: true,
                lastSynced: Date.now(),
            }
        }
    },
    Views: {
        ListView: ListView,
        ListViewFilter: ListViewFilter,
        EditView: EditView,
        DetailView: DetailView
    },
    Methods: {
        filterListView: filterListView
    },
    Permissions: {
        Edit: false,
        Create: false
    },
    ShowMenu: true,
    SelectLists: {
        A: "A"
    }
}
