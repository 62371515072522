import { useCallback, useRef } from "react"
import { useTranslation } from "react-i18next";

declare type Props = {
    record: { [key: string]: any }
    setRecord?: React.Dispatch<React.SetStateAction<{
        [key: string]: any;
    } | null>>
    id: string
    label?: string
    readonly?: boolean
    type?: string
}

const Input: React.FC<Props> = ({ record, setRecord, id, label, readonly, type }) => {
    const { t } = useTranslation();

    const myInput = useRef<any>(null)
    const setValue = useCallback((value) => {
        if (setRecord) {
            setRecord({
                ...record, [id]: value
            })
        }
    }, [record, setRecord, id])

    //console.log(record)
    return <div className="h-full w-full relative">
        <input ref={myInput} className={`appearance-none pt-4 pb-1 px-2 h-full w-full focus:outline-none bg-white
    border-b-primary border-b-2 text-lg font-semibold font-sans
    `} readOnly={readonly || false} type={type || "text"}
            onChange={(e) => { if (readonly) return; setValue(e.target.value); record[id] = e.target.value }} value={record[id]}></input>
        <label onClick={() => { myInput.current?.focus() }} className={`absolute text-primary font-bold transition-all duration-100 left-2
        ${typeof record[id] !== 'undefined' && record[id] !== "" ? "text-sm -top-1" : "text-md top-3"} `}>{t(label || "")}</label>
    </div>
}

export default Input