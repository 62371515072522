import { SearchIcon } from "@heroicons/react/outline"

declare type Props = {
    placeHolder?: string
    searchValue: string
    setSearchValue: React.Dispatch<React.SetStateAction<any>>
    className?: string
}

const SearchBar: React.FC<Props> = ({ searchValue, setSearchValue, className, placeHolder }) => {
    return <div className={className}>
        <input
            type="text"
            name="search"
            id="search"
            autoComplete="off"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className="z-10 border-2 absolute w-full pl-10 pr-4 rounded-md h-10 text-sm md:text-xl inset-y-0 left-0 bg-white outline-none"
            placeholder={placeHolder}
        />
        <div className="z-10 absolute pl-3 flex top-2 pointer-events-none">
            <SearchIcon
                className="flex-shrink-0 h-6 w-6 text-light-font-navbar-hover stroke-primary"
                aria-hidden="true"
            ></SearchIcon>
        </div>
        {/*for box size*/}
        <div className="pl-3 top-4 pointer-events-none h-12 w-8 "></div>
    </div>
}

export default SearchBar