import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { save } from "ionicons/icons";
import { useCallback } from "react";
import { EditViewComponentProps } from "../../../pages/EditView";

const EditView: React.FC<EditViewComponentProps> = ({ Document, handleSubmit }) => {
    /*
    const AuthContext = useAuth()
    const session = JSON.parse(AuthContext?.token || "")
    const userID = session["name_value_list"]['user_id']['value']

    // Modificaciones especiales de coleccion
    if (!Document["assigned_user_id"] || Document["assigned_user_id"] === "") {
        Document["assigned_user_id"] = userID
    }*/

    const validate = useCallback(() => {
        /* IMPLEMENT */

    }, [Document])

    return <>
        <div className="w-full xs:w-96 flex flex-col self-center px-2 relative h-fit" >
            <div className="w-full h-10 m-2 self-center">
            </div>
        </div>
        <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={() => handleSubmit(validate)}>
            <IonFabButton color="primary">
                <IonIcon icon={save} />
            </IonFabButton>
        </IonFab>
    </>
}

export default EditView