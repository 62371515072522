import { useCallback, useRef, useState } from "react"
import { IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { useTranslation } from "react-i18next";

declare type Props = {
    record: { [key: string]: any }
    setRecord?: React.Dispatch<React.SetStateAction<{
        [key: string]: any;
    } | null>>
    id: string
    label?: string
    options: { label: string, value: string }[]
    readonly?: boolean
}


const Select: React.FC<Props> = ({ record, id, label, options, setRecord, readonly }) => {
    const { t } = useTranslation();

    const [selected, setSelected] = useState(options.find((opt) => (opt.value === record[id]))?.value || "")
    const mySelect = useRef<any>(null)

    const setValue = useCallback((value) => {
        if (readonly) return
        if (setRecord) {
            setRecord({
                ...record, [id]: value
            })
        }
        setSelected(value)
    }, [record, setRecord, id, readonly])

    return (
        <div className="relative">
            <IonSelect ref={mySelect} interface="action-sheet" value={selected} className="bg-white border-b-primary border-b-2 pl-2 pt-5 pb-0.5 h-10" onIonChange={e => setValue(e.detail.value)}>
                {
                    readonly ?
                        options.filter((opt) => opt.value === selected).map((opt) => {
                            return <IonSelectOption key={opt.value} value={opt.value}>
                                {opt.label}
                            </IonSelectOption>
                        }) :
                        options.map((opt) => {
                            return <IonSelectOption key={opt.value} value={opt.value}>
                                {opt.label}
                            </IonSelectOption>
                        })
                }
            </IonSelect>
            <IonLabel onClick={(e) => { mySelect.current?.click() }} className={`absolute text-primary font-bold transition-all duration-100 left-2 z-10 
                    ${selected !== "" ? "text-sm -top-1" : "text-md top-3"} `}>{t(label || "")}</IonLabel>
        </div >
    )
}


export default Select