import { GlobalConfig } from "../config"
import qs from 'qs'

declare type LoginParams = {
    user_auth: {
        user_name: string
        password: string
    }
    application_name: string
    name_value_list: {
        language?: string
        notifyonsave?: boolean
    }
}

export const login = async (user_name: string, password: string, application_name?: string) => {
    const props: LoginParams = {
        user_auth: {
            user_name: user_name || "",
            password: password || ""
        },
        application_name: application_name || "",
        name_value_list: {}
    }

    const response = await Post2CRM("login", props)

    if (response.name === "Invalid Login") {
        throw Error("Invalid Login")
    }

    return response
}

declare type LogoutParams = {
    session: string
}

export const logout = async (session: string) => {
    const props: LogoutParams = {
        session: session
    }
    const response = await Post2CRM("logout", props).catch(e => { throw e })
    return response
}

declare type GetEntriesCountParams = {
    session: string
    module_name: string
    query: string
    deleted: boolean
}

export const get_entries_count = async (session: string, module_name: string, query: string, deleted: boolean) => {
    const props: GetEntriesCountParams = {
        session: session,
        module_name: module_name,
        query: query,
        deleted: deleted
    }
    const response = await Post2CRM("get_entries_count", props).catch(e => { throw e })
    return response
}

declare type NameValueList = {
    name: string
    value: string[]
}

declare type GetEntryListParams = {
    session: string
    module_name: string
    query: string
    order_by: string
    offset: number
    select_fields: string[]
    link_name_to_fields_array: NameValueList[]
    max_results: number
    deleted: boolean
}

export const get_entry_list = async (session: string, module_name: string, query: string, order_by: string, offset: number,
    select_fields: string[], link_name_to_fields_array: NameValueList[], max_results: number, deleted: boolean
) => {
    const props: GetEntryListParams = {
        session: session,
        module_name: module_name,
        query: query,
        order_by: order_by,
        offset: offset,
        select_fields: select_fields,
        link_name_to_fields_array: link_name_to_fields_array,
        max_results: max_results,
        deleted: deleted
    }
    const response = await Post2CRM("get_entry_list", props)
    return response
}


declare type SetEntryParams = {
    session: string
    module_name: string
    name_value_list: {
        name: string,
        value: any
    }[]
}

export const set_entry = async (session: string, module_name: string, record: { [key: string]: any }) => {
    const props: SetEntryParams = {
        session: session,
        module_name: module_name,
        name_value_list: []
    }

    for (var k in record) {
        if (k === "isSynced") continue

        props.name_value_list.push({
            name: k,
            value: record[k]
        });
    }

    const response = await Post2CRM("set_entry", props)
    return response
}


declare type post2CRM = {
    method: string
    input_type: string,
    response_type: string,
    rest_data: string,
}

const Post2CRM = async (method: string, restData: any) => {
    console.log(restData)
    const data: post2CRM = {
        method: method,
        input_type: "JSON",
        response_type: "JSON",
        rest_data: JSON.stringify(restData)
    }

    const response = await fetch(GlobalConfig.serverURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: qs.stringify(data), // body data type must match "Content-Type" header
    }).then(response => response.text())
        .then(response => JSON.parse(response));

    return response
}

