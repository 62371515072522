
import { useCallback, useEffect, useRef } from 'react';
import { GoogleMap } from '@capacitor/google-maps';
import { Geolocation } from '@awesome-cordova-plugins/geolocation'
import ub from '../ubicacion.png';

const MyMap: React.FC = () => {
    const mapRef = useRef<HTMLElement>();


    const createMap = useCallback(async () => {
        let newMap: GoogleMap;
        if (!mapRef.current) return;

        const location = await Geolocation.getCurrentPosition({ maximumAge: 0, timeout: 5000, enableHighAccuracy: true })

        newMap = await GoogleMap.create({
            id: 'my-cool-map',
            element: mapRef.current,
            apiKey: process.env.REACT_APP_YOUR_API_KEY_HERE || "",
            config: {
                center: {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude
                },
                zoom: 13
            },

        })

        await newMap.addMarker({
            coordinate: {
                lat: location.coords.latitude,
                lng: location.coords.longitude
            },
            iconUrl: ub,
        });

        //newMap.enableTrafficLayer(true)


        newMap.enableCurrentLocation(true)
    }, [])

    useEffect(() => { createMap() }, [createMap]);

    return (
        <capacitor-google-map className="w-full h-full" style={{
            display: 'inline-block',
            width: '100%',
            height: '100%',
        }} ref={mapRef}></capacitor-google-map>
    )
}

export default MyMap;