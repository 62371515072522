import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
/*
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Tailwind styles */
import './theme/tailwind.css';

import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { SQLiteHook, useSQLite } from 'react-sqlite-hook';
import { useCallback, useEffect, useState } from 'react';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './Hooks/useAuthentication';
import Home from './pages/Home';
import ListView from './pages/ListView';
import EditView from './pages/EditView';
import DetailView from './pages/DetailView';
import { Accounts } from './config/Modules/Accounts/Accounts';
import { AOS_Quotes } from './config/Modules/AOS_Quotes/AOS_Quotes';
import { AOS_Products } from './config/Modules/AOS_Products/AOS_Products';
import { Meetings } from './config/Modules/Meetings/Meetings';
import { Capacitor } from '@capacitor/core';
import MapView from './pages/Map';
import i18n from './i18n/i18n';
interface existingConnInterface {
  existConn: boolean,
  setExistConn: React.Dispatch<React.SetStateAction<boolean>>,
}

// Singleton SQLite Hook
export let sqlite: SQLiteHook;
// Existing Connections Store
export let existingConn: existingConnInterface;

setupIonicReact();

const App: React.FC = () => {
  i18n.init()

  const [existConn, setExistConn] = useState(false);
  existingConn = { existConn: existConn, setExistConn: setExistConn };


  sqlite = useSQLite();
  const platform = Capacitor.getPlatform();

  const permissions = useCallback(async () => {
    if (platform === 'android') {
      await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
        (result: any) => {
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) { AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION) }
        },
        (err: any) => AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
      );

      await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        (result: any) => {
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) { AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION) }
        },
        (err: any) => AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
      );

      await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
        (result: any) => {
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) { AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE) }
        },
        (err: any) => AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE)
      );

      await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
        (result: any) => {
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) { AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE) }
        },
        (err: any) => AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
      );
    }
  }, [platform]);

  const checkUpdate = useCallback(async () => {
    try {
      //TODO: WHEN IN APPSTORE
      /*if (platform == 'android') {

      if (currentV < latestV) {
        if (!alertedNewV) {
          alert("Nueva version disponible")
          localStorage.setItem("new_version", "true");
        }
        setAlertedNewV(true)
      } else {
        localStorage.removeItem("new_version");
      }
      //}*/
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    permissions();
    //checkUpdate();
  }, [checkUpdate, permissions])

  return (
    <IonApp>
      <AuthProvider>
        <IonReactRouter>
          <Switch>
            <Route path="/login" >
              <Login />
            </Route>
            <ProtectedRoute>
              <IonRouterOutlet id="main">
                <Switch>
                  <Route path="/" exact>
                    <Redirect to="/home" />
                    <Home />
                  </Route>
                  <Route path="/home">
                    <Home />
                  </Route>

                  {/* Map */}
                  <Route exact path={"/map"}  >
                    <MapView />
                  </Route>

                  {/* ACCOUNTS */}
                  <Route exact path={"/" + Accounts.url}  >
                    <ListView Collection={Accounts} />
                  </Route>
                  <Route exact path={"/" + Accounts.url + "/:id"}  >
                    <DetailView Collection={Accounts} />
                  </Route>
                  <Route exact path={"/" + Accounts.url + "/:id/edit"} >
                    <EditView Collection={Accounts} />
                  </Route>

                  {/* MEETINGS */}
                  <Route exact path={"/" + Meetings.url}  >
                    <ListView Collection={Meetings} />
                  </Route>
                  <Route exact path={"/" + Meetings.url + "/:id"}  >
                    <DetailView Collection={Meetings} />
                  </Route>
                  <Route exact path={"/" + Meetings.url + "/:id/edit"} >
                    <EditView Collection={Meetings} />
                  </Route>

                  {/* AOS_PRODUCTS */}
                  <Route exact path={"/" + AOS_Products.url}  >
                    <ListView Collection={AOS_Products} />
                  </Route>
                  <Route exact path={"/" + AOS_Products.url + "/:id"}  >
                    <DetailView Collection={AOS_Products} />
                  </Route>
                  <Route exact path={"/" + AOS_Products.url + "/:id/edit"} >
                    <EditView Collection={AOS_Products} />
                  </Route>

                  {/* AOS_QUOTES */}
                  <Route exact path={"/" + AOS_Quotes.url}  >
                    <ListView Collection={AOS_Quotes} />
                  </Route>
                  <Route exact path={"/" + AOS_Quotes.url + "/:id"}  >
                    <DetailView Collection={AOS_Quotes} />
                  </Route>
                  <Route exact path={"/" + AOS_Quotes.url + "/:id/edit"} >
                    <EditView Collection={AOS_Quotes} />
                  </Route>
                </Switch>
              </IonRouterOutlet>
            </ProtectedRoute>
          </Switch>
        </IonReactRouter>
      </AuthProvider>
    </IonApp >
  );
};

export default App;
