import { SQLiteDBConnection } from "react-sqlite-hook";
import SearchBar from "../../../components/Search";
import { FitlerProps, ListViewComponentProps } from "../../../pages/ListView";
import { AOS_Products } from "./AOS_Products";
import sin_imagen from '../../../sin_imagen.jpg';

export const filterListView = async (db: SQLiteDBConnection, search: { [key: string]: string }, page: number, pageSize: number) => {

    await db.open()

    const query = `SELECT * FROM ${AOS_Products.Name} WHERE name LIKE '%${search.search || ""}%' COLLATE NOCASE ORDER BY name LIMIT ${pageSize} OFFSET ${page * pageSize}`;
    const res: any = await db.query(query)

    return res.values
}


export const ListViewFilter: React.FC<FitlerProps> = ({ searchValues, setSearchValues }) => {

    const setSearchBar: React.Dispatch<React.SetStateAction<any>> = (newValue: string) => {
        setSearchValues(prevState => ({
            ...prevState,    // keep all other key-value pairs
            search: newValue // update the value of specific key
        }))
    }

    return (
        <SearchBar searchValue={searchValues.search || ""} setSearchValue={setSearchBar} className={"mt-1 relative w-full max-w-sm self-center"} ></SearchBar>
    )
}


const ListView: React.FC<ListViewComponentProps> = ({ Document }) => {
    return (<div className="w-full flex justify-between shrink-0 h-fit bg-white mb-1 rounded-md">
        <div className="w-full flex justify-start shrink-0 h-24 bg-white mb-1 rounded-md p-2">
            <div className="flex flex-col justify-between shrink-0">
                <img src={Document.url || sin_imagen} className="h-20 w-auto self-center" alt={Document.name}></img>
            </div>
            <div className="flex flex-col ml-2 justify-between">
                <div className="">{Document.name}</div>
                <div className="font-bold text-sm">$<span className="text-lg">{Document.price}</span></div>
                <div className="">Stock: {Document.stock_c}</div>
            </div>
        </div>
    </div>)
}

export default ListView