import { IonFab, IonFabButton, IonIcon, IonPopover } from "@ionic/react";
import { save } from "ionicons/icons";
import Input from "../../../components/Fields/Input";
import { useAuth } from "../../../Hooks/useAuthentication";
import { EditViewComponentProps } from "../../../pages/EditView";

import { useCallback, useEffect, useRef, useState } from "react";
import DateTime from "../../../components/Fields/DateTime";
import { Accounts } from "../Accounts/Accounts";
import Select from "../../../components/Fields/Select";
import { Meetings } from "./Meetings";
import { Virtuoso } from "react-virtuoso";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { GlobalConfig } from "../..";

const EditView: React.FC<EditViewComponentProps> = ({
  Document,
  setRecord,
  handleSubmit,
}) => {
  const AuthContext = useAuth();
  const session = JSON.parse(AuthContext?.token || "");
  const userID = session["name_value_list"]["user_id"]["value"];

  const [open, setOpen] = useState(false);
  const accountPopOver = useRef<any>();

  // Modificaciones especiales de coleccion
  if (!Document["assigned_user_id"] || Document["assigned_user_id"] === "") {
    Document["assigned_user_id"] = userID;
  }

  const validate = useCallback(() => {
    if (!Document?.name || Document.name === "") {
      return { error: true, message: "Ingrese Asunto" };
    }
    if (!Document?.date_start || Document.date_start === "") {
      return { error: true, message: "Fecha Inicio" };
    }
  }, [Document]);

  return (
    <>
      <div className="w-full xs:w-96 flex flex-col self-center px-2 h-fit">
        <button ref={accountPopOver} id="trigger-button">
          {" "}
        </button>
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            setRecord={setRecord}
            id={"name"}
            label={"Meetings_name"}
          />
        </div>
        <div className="w-full h-10 m-2 self-center">
          <Select
            record={Document}
            setRecord={setRecord}
            id={"status"}
            label={"Meetings_status"}
            options={Meetings.SelectLists.status}
          />
        </div>
        <div className="w-full h-10 m-2 self-center flex">
          <div className="w-1/2">
            <DateTime
              record={Document}
              setRecord={setRecord}
              id={"date_start"}
              label={"Meetings_date_start"}
            />
          </div>
          <div className="w-1/2">
            <DateTime
              record={Document}
              setRecord={setRecord}
              id={"date_end"}
              label={"Meetings_date_end"}
              min={Document.date_start}
            />
          </div>
        </div>
        <div className="w-full h-10 m-2 self-center">
          <div
            className="h-full"
            onClick={() => {
              accountPopOver.current.click();
              setOpen(true);
            }}
          >
            <Input
              record={Document}
              id={"parent_name"}
              label={"Accounts"}
              readonly
            />
          </div>
          <AccountsPopOver
            Document={Document}
            setRecord={setRecord}
            open={open}
            setOpen={setOpen}
          />
        </div>
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            setRecord={setRecord}
            id={"description"}
            label={"Meetings_description"}
          />
        </div>
      </div>
      <IonFab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        onClick={() => handleSubmit(validate)}
      >
        <IonFabButton color="primary">
          <IonIcon icon={save} />
        </IonFabButton>
      </IonFab>
    </>
  );
};

declare type PopOverProps = {
  Document: {
    [key: string]: any;
  };
  setRecord: React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    } | null>
  >;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccountsPopOver: React.FC<PopOverProps> = ({
  Document,
  setRecord,
  open,
  setOpen,
}) => {
  const pageSize = 20;

  const [page, setPage] = useState(0);
  const [searchValues, setSearchValues] = useState<{ [key: string]: any }>({});
  const [filteredRecords, setFilteredRecords] = useState<
    { [key: string]: any }[]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const [db, setDB] = useState<SQLiteDBConnection | null>(null);

  const initialFilter = useCallback(
    async (page: number, pageSize: number) => {
      if (db) {
        const records = await Accounts.Methods.filterListView(
          db,
          searchValues,
          page,
          pageSize
        );
        if (records.length === 0) {
          setHasMore(false);
        }
        setFilteredRecords(records);
      }
    },
    [db, searchValues]
  );

  const connectToDB = useCallback(async () => {
    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
    let dbConn: SQLiteDBConnection;
    if (ret.result && isConn) {
      dbConn = await sqlite.retrieveConnection(GlobalConfig.db);
    } else {
      dbConn = await sqlite.createConnection(
        GlobalConfig.db,
        false,
        "no-encryption",
        1
      );
    }
    setDB(dbConn);
  }, []);

  useEffect(() => {
    connectToDB();
  }, [connectToDB]);

  useEffect(() => {
    const reset = 0;
    setPage(reset);
    setHasMore(true);
    initialFilter(reset, pageSize);
  }, [searchValues, initialFilter]);

  const onSelect = useCallback(
    (selected: any) => {
      setRecord({
        ...Document,
        parent_id: selected.parent_id,
        parent_name: selected.parent_name,
        parent_type: Accounts.Name,
      });
      setOpen(false);
    },
    [Document, setRecord, setOpen]
  );

  const fetchMoreData = async (ev: any) => {
    if (hasMore && db) {
      const records = await Accounts.Methods.filterListView(
        db,
        searchValues,
        page + 1,
        pageSize
      );
      if (records.length === 0) {
        console.log("no more");
        setHasMore(false);
      }

      setFilteredRecords(filteredRecords.concat(records));
      setPage(page + 1);
      //ev.target.complete();
    }
  };

  return (
    <IonPopover
      trigger="trigger-button"
      arrow={false}
      size="cover"
      alignment="center"
      isOpen={open}
      onDidDismiss={() => setOpen(false)}
    >
      <div className="h-[70vh]  p-2">
        <div className="h-10">
          {Accounts.Views && (
            <Accounts.Views.ListViewFilter
              searchValues={searchValues}
              setSearchValues={setSearchValues}
            />
          )}
        </div>
        {filteredRecords.length > 0 && (
          <>
            <div className="h-80">
              <Virtuoso
                className="ion-content-scroll-host w-full  self-center"
                style={{ height: "100%" }}
                data={filteredRecords}
                endReached={fetchMoreData}
                itemContent={(index, doc) => {
                  return (
                    <div
                      className="w-full flex justify-between shrink-0 h-10 bg-white mb-1 rounded-md"
                      onClick={() =>
                        onSelect({ parent_id: doc?.id, parent_name: doc.name })
                      }
                    >
                      <p className="self-center">{doc?.name}</p>
                    </div>
                  );
                }}
              />
            </div>
          </>
        )}
      </div>
    </IonPopover>
  );
};
export default EditView;
