import React from "react";
import { login, logout } from "./useSuiteCRM";

declare type AuthContextType = {
    token: string | null;
    onLogin: (history: any, username: string, password: string, redirect?: string) => Promise<void>;
    onLogout: (history: any) => void;
} | null

const AuthContext = React.createContext<AuthContextType>(null);

export const useAuth = () => {
    return React.useContext<AuthContextType>(AuthContext);
};

declare type Props = {
    children: JSX.Element | JSX.Element[],
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
    //const navigate = useNavigate();
    //const location = useLocation();
    //const state = location.state as LocationState

    const [token, setToken] = React.useState(() => {
        return getStorageValue("token");
    });

    const handleLogin = async (history: any, username: string, password: string, redirect?: string) => {
        const token = await login(username, password).catch(e => { throw e });


        localStorage.setItem("token", JSON.stringify(token));

        localStorage.setItem("username", username);
        localStorage.setItem("password", password);

        setToken(JSON.stringify(token));

        if (redirect !== undefined) {
            const origin = redirect !== "" ? redirect : '/home';
            history.replace(origin)
        }
        //navigate(origin);
    };

    const handleLogout = (history: any) => {

        const session = JSON.parse(token || "")
        const sessionID = session["id"]
        logout(sessionID)

        localStorage.removeItem("token");
        setToken(null);

        history.replace("/login")
        //navigate("/login");
    };

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

function getStorageValue(key: string) {
    // getting stored value
    if (typeof window !== "undefined") {
        const saved = localStorage.getItem(key);
        const initial = saved;
        return initial;
    }
    return null
}


