import { SQLiteDBConnection } from "react-sqlite-hook";
import { Accounts } from "./Modules/Accounts/Accounts";
import { AOS_Products } from "./Modules/AOS_Products/AOS_Products";
import { AOS_Products_Quotes } from "./Modules/AOS_Products_Quotes/AOS_Products_Quotes";
import { AOS_Quotes } from "./Modules/AOS_Quotes/AOS_Quotes";
import { Meetings } from "./Modules/Meetings/Meetings";

const GlobalConfig = {
  serverURL: "https://wms.cerezosoftware.com/service/v4_1/rest.php",
  currency_id: "50b0672f-ecc0-dced-e05d-5949759e3970",
  fecha_mayor_a: "DATE_SUB(NOW(), INTERVAL 1 YEAR)",
  intervalo_rastreo: "DATE_SUB(NOW(), INTERVAL 10 MINUTE)",
  validar_stock: false,
  formato_fechahora_db: "yyyy-MM-dd HH:mm:ss",
  formato_hora: "HH:mm:ss",
  formato_fecha: "dd/MM/yyyy",
  distancia_permitida: 99999, //Metros
  db: "wms",
};

export const Modulos: Modulo[] = [
  Accounts,
  Meetings,
  AOS_Products,
  AOS_Quotes,
  AOS_Products_Quotes,
];

export interface Modulo {
  url: string;
  Name: string;
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  Schema: { [key: string]: string };
  CRMFetch: CRMFetch;
  Views: {
    ListView: React.FC<any>;
    ListViewFilter: React.FC<any>;
    DetailView: React.FC<any>;
    EditView: React.FC<any>;
  };
  Methods: {
    filterListView: (
      db: SQLiteDBConnection,
      searchValues: { [key: string]: any },
      page: number,
      pageSize: number
    ) => any;
  };
  Permissions: {
    Edit: boolean;
    Create: boolean;
  };
  ShowMenu: boolean;
  SelectLists: { [key: string]: any };
}

export interface CRMFetch {
  Fields: string[];
  Relationships: NameValueList[];
  Query: (currentUser: string, gloablConfig: any) => string;
  OrderBy: string;
  Result2Object: (result: any) => { [key: string]: any };
}

export interface NameValueList {
  name: string;
  value: string[];
}

export { GlobalConfig };
