import { Link } from "react-router-dom";
import EditFab from "../../../components/EditFab";
import DateTime from "../../../components/Fields/DateTime";
import Input from "../../../components/Fields/Input";
import Select from "../../../components/Fields/Select";
import { DetailViewComponentProps } from "../../../pages/DetailView";
import { Accounts } from "../Accounts/Accounts";
import { Meetings } from "./Meetings";

const DetailView: React.FC<DetailViewComponentProps> = ({ Document }) => {
  console.log(Document);
  return (
    <>
      <div className="w-full xs:w-96 flex flex-col self-center px-2 h-fit">
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            id={"name"}
            label={"Meetings_name"}
            readonly
          />
        </div>
        <div className="w-full h-10 m-2 self-center">
          <Select
            record={Document}
            id={"status"}
            label={"Meetings_status"}
            options={Meetings.SelectLists.status}
            readonly
          />
        </div>
        <div className="w-full h-10 m-2 self-center flex">
          <div className="w-1/2">
            <DateTime
              record={Document}
              id={"date_start"}
              label={"Meetings_date_start"}
              readonly
            />
          </div>
          <div className="w-1/2">
            <DateTime
              record={Document}
              id={"date_end"}
              label={"Meetings_date_end"}
              readonly
            />
          </div>
        </div>
        <div className="w-full h-10 m-2 self-center">
          {Document.parent_id !== "" ? (
            <Link to={`/${Accounts.url}/${Document.parent_id}`}>
              <Input
                record={Document}
                id={"parent_name"}
                label={"Accounts"}
                readonly
              />
            </Link>
          ) : (
            <Input
              record={Document}
              id={"parent_name"}
              label={"Accounts"}
              readonly
            />
          )}
        </div>
        <div className="w-full h-10 m-2 self-center">
          <Input
            record={Document}
            id={"description"}
            label={"Meetings_description"}
            readonly
          />
        </div>
      </div>
      {Meetings.Permissions.Edit && (
        <Link
          to={{
            pathname: "/" + Meetings.url + "/" + Document.id + "/edit",
            state: { record: Document },
          }}
        >
          <EditFab />
        </Link>
      )}
    </>
  );
};

export default DetailView;
