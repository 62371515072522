import { IonFab, IonFabButton, IonIcon, IonPopover, IonSegment, IonSegmentButton } from "@ionic/react";
import { save } from "ionicons/icons";
import Input from "../../../components/Fields/Input";

import { EditViewComponentProps } from "../../../pages/EditView";
import { ShoppingCartIcon, PlusIcon, ClipboardListIcon } from '@heroicons/react/outline'
import { useCallback, useEffect, useRef, useState } from "react";
import { sqlite } from "../../../App";
import { GlobalConfig } from "../..";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { AOS_Products as AOSProducts } from "../AOS_Products/AOS_Products";
import { AOS_Products_Quotes as AOSProductsQuotes } from "../AOS_Products_Quotes/AOS_Products_Quotes";
import { Virtuoso } from "react-virtuoso";
import SignatureCanvas from 'react-signature-canvas'

import Date from "../../../components/Fields/Date";
import Select from "../../../components/Fields/Select";
import { AOS_Quotes } from "./AOS_Quotes";
import { Accounts } from "../Accounts/Accounts";
import { useAuth } from "../../../Hooks/useAuthentication";
import { useTranslation } from "react-i18next";


const EditView: React.FC<EditViewComponentProps> = ({ Document, setRecord, handleSubmit }) => {
    const { t } = useTranslation();
    const [tab, setTab] = useState("info")
    const [signing, setSigning] = useState(false)
    const [lineItems, setLineItems] = useState<Map<string, { [key: string]: any }>>(new Map<string, { [key: string]: any }>())
    const [newLineItems, setNewLineItems] = useState<Map<string, { [key: string]: any }>>(new Map<string, { [key: string]: any }>())

    const [open, setOpen] = useState(false)
    const accountPopOver = useRef<any>()

    const sigPad = useRef<any>()

    const validate = useCallback(() => {
        Document.description = sigPad.current.getTrimmedCanvas().toDataURL()

        /* IMPLEMENT */

    }, [Document])

    const AuthContext = useAuth()
    const session = JSON.parse(AuthContext?.token || "")
    const userID = session["name_value_list"]['user_id']['value']

    // Modificaciones especiales de coleccion
    if (!Document["assigned_user_id"] || Document["assigned_user_id"] === "") {
        Document["assigned_user_id"] = userID
    }


    const getLineItems = useCallback(async () => {
        // retrieve the connections
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        var db: SQLiteDBConnection
        if (ret.result && isConn) {
            db = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            db = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
        }

        // must open connection
        await db.open();

        const lines = await db.query(`SELECT * FROM ${AOSProductsQuotes.Name} WHERE parent_id='${Document.id}'`)

        const recordedLineItems = new Map<string, { [key: string]: any }>()
        lines?.values?.forEach((line) => {
            recordedLineItems.set(line.product_id, line)
        })
        setLineItems(recordedLineItems)
    }, [Document.id])


    const saveLines = () => {

        const querys: any[] = []
        lineItems.forEach((line) => {
            let campos = Object.keys(line).join()
            let values = "'" + Object.values(line).join("','") + "'"

            querys.push({ statement: `INSERT OR REPLACE INTO ${AOSProductsQuotes.Name}(${campos}) VALUES(${values});`, values: [] });
        })

        newLineItems.forEach((line) => {
            let campos = Object.keys(line).join()
            let values = "'" + Object.values(line).join("','") + "'"

            querys.push({ statement: `INSERT OR REPLACE INTO ${AOSProductsQuotes.Name}(${campos}) VALUES(${values});`, values: [] });
        })

        return querys
    }

    useEffect(() => {
        getLineItems()
    }, [getLineItems])

    return <>
        <IonSegment value={tab} onIonChange={(e: any) => setTab(e.detail.value)} className="shrink-0 py-1">
            <IonSegmentButton value="info">
                <ClipboardListIcon className="stroke-2 stroke-primary h-7 w-7"></ClipboardListIcon>
            </IonSegmentButton>
            <IonSegmentButton value="cart">
                <ShoppingCartIcon className="stroke-2 stroke-primary h-7 w-7"></ShoppingCartIcon>
            </IonSegmentButton>
            <IonSegmentButton value="add">
                <PlusIcon className="stroke-2 stroke-primary h-7 w-7"></PlusIcon>
            </IonSegmentButton>
        </IonSegment>

        <div className="w-full xs:w-96 flex flex-col self-center px-2 h-fit" >
            <button ref={accountPopOver} id="trigger-button"> </button>
            <AccountsPopOver Document={Document} setRecord={setRecord} open={open} setOpen={setOpen} />
        </div>
        {tab === "info" &&
            <div className="w-full xs:w-96 flex flex-col self-center px-2 h-fit" >
                <div className="w-full h-10 m-2 self-center">
                    <Input record={Document} setRecord={setRecord} id={"name"} label={"AOS_Quotes_name"} />
                </div>
                <div className="w-full h-10 m-2 self-center">
                    <div className="h-full" onClick={() => { accountPopOver.current.click(); setOpen(true) }}>
                        <Input record={Document} id={"billing_account"} label={"Accounts"} readonly />
                    </div>
                </div>
                <div className="w-full h-10 m-2 self-center flex space-x-1">
                    <div className="w-1/2">
                        <Input record={Document} setRecord={setRecord} id={"number"} label={"AOS_Quotes_number"} readonly />
                    </div>
                    <div className="w-1/2">
                        <Select record={Document} setRecord={setRecord} id={"stage"} label={"AOS_Quotes_stage"} options={AOS_Quotes.SelectLists.stage} />
                    </div>
                </div>
                <div className="w-full h-10 m-2 self-center flex space-x-1">
                    <div className="w-1/2">
                        <Date record={Document} setRecord={setRecord} id={"expiration"} label={"AOS_Quotes_expiration"} />
                    </div>
                    <div className="w-1/2">
                        <Select record={Document} setRecord={setRecord} id={"invoice_status"} label={"AOS_Quotes_invoice_status"} options={AOS_Quotes.SelectLists.statusFact} />
                    </div>
                </div>
                <div className="w-full h-10 m-2 self-center flex space-x-1">
                    <div className="w-1/2">
                        <Select record={Document} setRecord={setRecord} id={"approval_status"} label={"AOS_Quotes_approval_status"} options={AOS_Quotes.SelectLists.statusApprov} />
                    </div>
                    <div className="w-1/2">
                        <Select record={Document} setRecord={setRecord} id={"term"} label={"AOS_Quotes_term"} options={AOS_Quotes.SelectLists.term} />
                    </div>
                </div>
                <div className="h-5"></div>
            </div>}


        {tab === "add" && <AvailableProducts lineItems={lineItems} setLineItems={setLineItems}
            newLineItems={newLineItems} setNewLineItems={setNewLineItems} record={Document} setRecord={setRecord}
        ></AvailableProducts>}
        {tab === "cart" && <><Cart lineItems={lineItems} setLineItems={setLineItems}
            newLineItems={newLineItems} setNewLineItems={setNewLineItems} record={Document} setRecord={setRecord}></Cart>

        </>}

        <div className="w-full xs:w-96 flex flex-col self-center px-2 h-fit" >
            <div className="w-full h-10 m-2 self-center flex">
                <Input record={Document} id={"total_amount"} label={"AOS_total_amount"} readonly />
            </div>
        </div>

        {signing && (
            <>
                <div className="h-screen w-full bg-backgound absolute opacity-80 z-20">
                </div>
                <div className="h-fit w-11/12 xs:w-96 absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
                    <div className="h-96 shadow-md shadow-black overflow-hidden">
                        <SignatureCanvas canvasProps={{ height: 500, width: 500, className: "bg-white" }}
                            ref={sigPad} />
                    </div>
                    <div className="flex w-full">
                        <button className="h-12 bottom-10 w-2/6 self-center m-4 p-1 py-2 rounded-md bg-primary text-font-primary flex justify-center" type="button" onClick={() => sigPad.current.clear()}>
                            <p className="absolute self-center font-semibold z-50">{t("Delete")}</p>
                        </button>
                        <button className="h-12 bottom-10 w-2/6 self-center m-4 p-1 py-2 rounded-md bg-primary text-font-primary flex justify-center" type="button" onClick={() => handleSubmit(validate, saveLines)}>
                            <p className="absolute self-center font-semibold z-50">{t("Confirm")}</p>
                        </button>
                        <button className="h-12 bottom-10 w-2/6 self-center m-4 p-1 py-2 rounded-md bg-primary text-font-primary flex justify-center" type="button" onClick={() => { sigPad.current.clear(); setSigning(false) }}>
                            <p className="absolute self-center font-semibold z-50">{t("Cancel")}</p>
                        </button>
                    </div>
                </div>

            </>
        )}


        {!signing &&
            <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={() => setSigning(true)}>
                <IonFabButton color="primary">
                    <IonIcon icon={save} />
                </IonFabButton>
            </IonFab>
        }
    </>
}

declare type CartProps = {
    lineItems: Map<string, { [key: string]: any }>
    setLineItems: React.Dispatch<React.SetStateAction<Map<string, { [key: string]: any; }>>>
    newLineItems: Map<string, { [key: string]: any }>
    setNewLineItems: React.Dispatch<React.SetStateAction<Map<string, { [key: string]: any; }>>>
    record: { [key: string]: any }
    setRecord: React.Dispatch<React.SetStateAction<{ [key: string]: any; } | null>>
}

const Cart: React.FC<CartProps> = ({ lineItems, setLineItems, newLineItems, setNewLineItems, record, setRecord }) => {


    return <>
        <Virtuoso className="ion-content-scroll-host w-full xs:w-96 self-center"
            style={{ height: '100%' }}
            data={Array.from(lineItems.values()).concat(Array.from(newLineItems.values()))}
            itemContent={(index, doc) => {
                if (AOSProducts.Views) {
                    return <AOSProductsQuotes.Views.EditView key={index} Document={doc} lineItems={lineItems} setLineItems={setLineItems}
                        newLineItems={newLineItems} setNewLineItems={setNewLineItems} parent={record} setRecord={setRecord}
                    />
                }
            }}
        />
    </>
}


declare type AvailableProductsProps = {
    lineItems: Map<string, { [key: string]: any }>
    setLineItems: React.Dispatch<React.SetStateAction<Map<string, { [key: string]: any; }>>>
    newLineItems: Map<string, { [key: string]: any }>
    setNewLineItems: React.Dispatch<React.SetStateAction<Map<string, { [key: string]: any; }>>>
    record: { [key: string]: any }
    setRecord: React.Dispatch<React.SetStateAction<{ [key: string]: any; } | null>>
}

const AvailableProducts: React.FC<AvailableProductsProps> = ({ lineItems, setLineItems, newLineItems, setNewLineItems, record, setRecord }) => {
    const pageSize = 20
    const [page, setPage] = useState(0)
    const [searchValues, setSearchValues] = useState<{ [key: string]: any }>({})
    const [filteredRecords, setFilteredRecords] = useState<{ [key: string]: any }[]>([])
    const [hasMore, setHasMore] = useState(true)
    const [db, setDB] = useState<SQLiteDBConnection | null>(null)



    const initialFilter = useCallback(async (page: number, pageSize: number) => {
        if (db) {
            const records = await AOSProducts.Methods.filterListView(db, searchValues, page, pageSize)
            if (records.length === 0) {
                setHasMore(false)
            }
            setFilteredRecords(records)
        }
    }, [db, searchValues])

    const connectToDB = useCallback(async () => {
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        let dbConn: SQLiteDBConnection
        if (ret.result && isConn) {
            dbConn = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            dbConn = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
        }
        setDB(dbConn)
    }, [])

    useEffect(() => {
        connectToDB()
    }, [connectToDB])

    useEffect(() => {
        const reset = 0
        setPage(reset)
        setHasMore(true)
        initialFilter(reset, pageSize)
    }, [searchValues, initialFilter])


    const fetchMoreData = async (ev: any) => {
        if (hasMore && db) {
            const records = await AOSProducts.Methods.filterListView(db, searchValues, page + 1, pageSize)
            if (records.length === 0) {
                console.log("no more")
                setHasMore(false)
            }

            setFilteredRecords(filteredRecords.concat(records));
            setPage(page + 1)
            //ev.target.complete();
        }
    };

    return <div className="w-full h-full flex flex-col bg-backgound">
        {AOSProducts.Views &&
            <AOSProducts.Views.ListViewFilter searchValues={searchValues} setSearchValues={setSearchValues} />}
        {filteredRecords.length > 0 && <>
            <Virtuoso className="ion-content-scroll-host w-full xs:w-96 self-center"
                style={{ height: '100%' }}
                data={filteredRecords}
                endReached={fetchMoreData}
                itemContent={(index, doc) => {
                    if (AOSProducts.Views) {
                        return <AOSProductsQuotes.Views.EditView key={index} Document={doc} lineItems={lineItems} setLineItems={setLineItems}
                            newLineItems={newLineItems} setNewLineItems={setNewLineItems} parent={record} setRecord={setRecord} />
                    }
                }}
            />
        </>}
    </div>
}

declare type PopOverProps = {
    Document: {
        [key: string]: any;
    }
    setRecord: React.Dispatch<React.SetStateAction<{
        [key: string]: any;
    } | null>>
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AccountsPopOver: React.FC<PopOverProps> = ({ Document, setRecord, open, setOpen }) => {
    const pageSize = 20

    const [page, setPage] = useState(0)
    const [searchValues, setSearchValues] = useState<{ [key: string]: any }>({})
    const [filteredRecords, setFilteredRecords] = useState<{ [key: string]: any }[]>([])
    const [hasMore, setHasMore] = useState(true)
    const [db, setDB] = useState<SQLiteDBConnection | null>(null)

    const initialFilter = useCallback(async (page: number, pageSize: number) => {
        if (db) {
            const records = await Accounts.Methods.filterListView(db, searchValues, page, pageSize)
            if (records.length === 0) {
                setHasMore(false)
            }
            setFilteredRecords(records)
        }
    }, [db, searchValues])

    const connectToDB = useCallback(async () => {
        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
        let dbConn: SQLiteDBConnection
        if (ret.result && isConn) {
            dbConn = await sqlite.retrieveConnection(GlobalConfig.db);
        } else {
            dbConn = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
        }
        setDB(dbConn)
    }, [])

    useEffect(() => {
        connectToDB()
    }, [connectToDB])

    useEffect(() => {
        const reset = 0
        setPage(reset)
        setHasMore(true)
        initialFilter(reset, pageSize)
    }, [searchValues, initialFilter])

    const onSelect = useCallback((selected: any) => {
        setRecord({ ...Document, billing_account_id: selected.billing_account_id, billing_account: selected.billing_account });
        setOpen(false);
    }, [Document, setRecord, setOpen])


    const fetchMoreData = async (ev: any) => {
        if (hasMore && db) {
            const records = await Accounts.Methods.filterListView(db, searchValues, page + 1, pageSize)
            if (records.length === 0) {
                console.log("no more")
                setHasMore(false)
            }

            setFilteredRecords(filteredRecords.concat(records));
            setPage(page + 1)
            //ev.target.complete();
        }
    };


    return (
        <IonPopover trigger="trigger-button" arrow={false} size="cover" alignment="center" isOpen={open}
            onDidDismiss={() => setOpen(false)}>
            <div className="h-[70vh] p-2">
                <div className="h-10">
                    {Accounts.Views &&
                        <Accounts.Views.ListViewFilter searchValues={searchValues} setSearchValues={setSearchValues} />}
                </div>
                {filteredRecords.length > 0 && <>
                    <div className="h-80">
                        <Virtuoso className="ion-content-scroll-host w-full self-center"
                            style={{ height: '100%' }}
                            data={filteredRecords}
                            endReached={fetchMoreData}
                            itemContent={(index, doc) => {
                                return <div className="w-full flex justify-between shrink-0 h-10 bg-white mb-1 rounded-md"
                                    onClick={() => onSelect({ billing_account_id: doc?.id, billing_account: doc.name })}>
                                    <p className="self-center" >{doc?.name}</p>
                                </div>
                            }}
                        />
                    </div>
                </>}

            </div>
        </IonPopover>
    )
}

export default EditView