import { Sync } from "../SVG/sync"
import { useCallback, useEffect, useState } from "react";
import ModuleStatus from "../components/ModuleStatus";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline"
import { GlobalConfig, Modulos } from "../config";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useAuth } from "../Hooks/useAuthentication";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sqlite } from "../App";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { createDb } from "../Hooks/createDB";


const Home: React.FC = () => {
    const { t } = useTranslation();
    const AuthContext = useAuth()
    const [present] = useIonToast();
    let history = useHistory<any>();

    const [syncing, setSyncing] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [dowloading, setDowloading] = useState(false)
    const [syncIndex, setSyncIndex] = useState(0)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (error) {
            setSyncIndex(0)
            setSyncing(false)
            setUploading(false)
            setDowloading(false)
        }
    }, [error])

    const handleSync = useCallback(async () => {
        if (Modulos.length === syncIndex) {
            if (uploading) {
                if (localStorage.getItem("cleanDB")) {
                    // retrieve the connections
                    const ret = await sqlite.checkConnectionsConsistency();
                    const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
                    var db: SQLiteDBConnection
                    if (ret.result && isConn) {
                        db = await sqlite.retrieveConnection(GlobalConfig.db);
                    } else {
                        db = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
                    }

                    // must open connection
                    await db.open();
                    await db.delete();
                    await createDb(sqlite);
                    localStorage.removeItem("cleanDB")
                }
                setUploading(false)
                setSyncIndex(0)
                return
            }

            if (dowloading) {
                setDowloading(false)
                setSyncing(false)
                setSuccess(true)
                setSyncIndex(0)
                return
            }
        }

    }, [syncIndex, dowloading, uploading])

    useEffect(() => {
        handleSync()
    }, [handleSync]);

    const startSync = useCallback(async () => {
        if (syncing) return

        // Iniciar sesion
        setSyncIndex(0);
        setError(false);
        setSyncing(true);
        setSuccess(false);

        try {
            const username = localStorage.getItem("username") || ""
            const password = localStorage.getItem("password") || ""
            await AuthContext?.onLogin(history, username, password);
        } catch (e: any) {
            present({
                position: 'top',
                message: e,
                duration: 4000,
                color: "danger"
            })

            if (e !== "TypeError: Failed to fetch") {
                AuthContext?.onLogout(history)
            }
            setError(true);
            return
        }

        setUploading(true);
        setDowloading(true);
    }, [AuthContext, history, present, syncing])

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>{t('Home')}</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
            <div className="h-full w-full flex flex-col justify-between bg-backgound">
                <div className="w-full xs:w-96 self-center top-0 sticky z-10 pb-1 px-2 mt-3">
                    <div className="bg-white w-full h-24 self-center flex rounded-md p-2 ">
                        {success ?
                            <CheckCircleIcon className={`w-11 h-11 stroke-green-500 self-center`} /> :
                            error ? <XCircleIcon className={`w-11 h-11 stroke-red-500 self-center`} /> :
                                <Sync className={`w-10 h-10 fill-primary self-center ${syncing && 'animate-spin'}`} />
                        }
                        <div className="self-center ml-2">
                            <p className="font-bold text-lg">{t("Account synchronization")}</p>
                            <span><span className="text-font-alert">{t('Last synchronization')}:</span> {success ? "Ahora" : "Fecha"}</span>
                        </div>
                    </div>
                </div>
                {/* Lo que hago es restarle la altura de header, el bloque de arriba y el boton */}
                <div className="w-full xs:w-96 flex flex-col self-center px-2 relative grow max-h-[calc(90vh_-_96px_-_48px_-_40px)] overflow-auto">
                    {
                        Modulos.map((col, idx) => (
                            <ModuleStatus key={idx} Collection={col} collectionIndex={idx}
                                syncing={syncing} uploading={uploading} dowloading={dowloading}
                                syncIndex={syncIndex} setSyncIndex={setSyncIndex} setError={setError}
                            />
                        ))
                    }
                </div >
                <button className="h-12 bottom-10 w-11/12 xs:w-96 self-center m-4 p-1 py-2 rounded-md bg-primary text-font-primary flex justify-center" type="button" onClick={() => startSync()}>
                    {syncing ?
                        <div className="animate-spin border-4 h-7 w-7 rounded-full border-t-secondary border-backgound self-center"></div> :
                        <p className="self-center font-semibold">{t('Synchronize')}</p>}
                </button>
            </div >
        </IonContent>
    </IonPage>
}

export default Home