import { SQLiteConnection, SQLiteDBConnection } from "@capacitor-community/sqlite";
import { Capacitor } from "@capacitor/core";
import { SQLiteHook } from "react-sqlite-hook";
import { GlobalConfig, Modulos } from "../config";

export const createDb = async (sqlite: SQLiteConnection | SQLiteHook)  => {
  const platform = Capacitor.getPlatform();
  try {
    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection(GlobalConfig.db)).result;
    var db: SQLiteDBConnection
    if (ret?.result && isConn) {
      db = await sqlite.retrieveConnection(GlobalConfig.db);
    } else {
      db = await sqlite.createConnection(GlobalConfig.db, false, "no-encryption", 1);
    }

    await db.open().catch((e) => { console.log("5" + e) });;

    // Cargar Modulos
    const querys: any = []
    await Promise.all(
      Modulos.map(async (modulo) => {
        const NewQuerys = [{ statement: `CREATE TABLE IF NOT EXISTS ${modulo.Name} (id string primary key);`, values: [] }]
        querys.push(...NewQuerys)
      })
    )
    if (querys.length > 0) {
      await db.executeSet(querys).catch((e) => { console.log("3" + e) });
    }

    const querysFields: any = []
    await Promise.all(
      Modulos.map(async (modulo) => {
        // Create Table with key
        const res: any = await db.query(`PRAGMA table_info(${modulo.Name})`).catch((e) => { console.log("4" + e) });;
        //console.log(res) 

        const existingColumns: string[] = []
        res?.values?.forEach((column: any) => {
          existingColumns.push(column.name)
        })

        const NewQuerys = []

        for (var k in modulo.Schema) {
          if (k === "id" || existingColumns.includes(k)) {
            continue
          }
          NewQuerys.push({ statement: `ALTER TABLE ${modulo.Name} ADD COLUMN ${k} ${modulo.Schema[k]};`, values: [] });
        }

        querysFields.push(...NewQuerys)
      })
    )

    if (querysFields.length > 0) {
      await db.executeSet(querysFields).catch((e) => { console.log("3" + e) });;
    }

    if (platform === 'web') {
      // save the db to store
      await sqlite.saveToStore(GlobalConfig.db).catch((e) => { console.log("2" + e) });
    }
    await db.close().catch((e) => { console.log("1" + e) });

} catch (e) {
  console.log("AAAAAAAAAAAAAA" + e);
  throw e
}
}