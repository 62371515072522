import { IonFab, IonFabButton, IonIcon } from "@ionic/react"
import { pencil } from "ionicons/icons"

const EditFab = () => {
    return <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton color="primary"  >
            <IonIcon icon={pencil} />
        </IonFabButton>
    </IonFab>
}

export default EditFab